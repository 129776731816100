import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import {z} from 'zod';

export const YoutubeProfilePropertiesSchema = BaseProfilePropertiesSchema.extend({
  channelHandle: z.string().nullable(),
  channelTitle: z.string(),
  subscribersCount: z.number(),
  videosCount: z.number(),
  viewsCount: z.number(),
  isVerified: z.boolean(),
  isMonetized: z.boolean(),
});
export type YoutubeProfileProperties = z.infer<typeof YoutubeProfilePropertiesSchema>;

export const YoutubeVideoSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  publishedAt: z.date(),
  thumbnailUrl: z.string(),
});
export type YoutubeVideo = z.infer<typeof YoutubeVideoSchema>;

export function getYoutubeProfileUrl(channelId: string): string {
  return `https://www.youtube.com/channel/${channelId}`;
}

export function getYoutubeVideoUrl(videoId: string): string {
  return `https://www.youtube.com/watch?v=${videoId}`;
}

export function getYoutubeCommentUrl(videoId: string, commentId: string): string {
  return `https://www.youtube.com/watch?v=${videoId}&lc=${commentId}`;
}
