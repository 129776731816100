import {
  LanguageSchema,
  LocalizedRichTextSchema,
  LocalizedStringSchema,
} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const CohortFormQuestionTypeSchema = z.enum([
  'checkbox',
  'date',
  'email',
  'number',
  'text',
  'select',
]);
export type CohortFormQuestionType = z.infer<typeof CohortFormQuestionTypeSchema>;

export const CohortFormOptionSchema = z.object({
  value: z.string().min(1),
  label: z.record(LanguageSchema, z.string().min(1).or(z.null())),
});
export type CohortFormOption = z.infer<typeof CohortFormOptionSchema>;

export const CohortFormQuestionSchema = z
  .object({
    id: z.string().uuid(),
    name: LocalizedStringSchema,
    referenceId: z.string().min(1).nullable(),
    type: CohortFormQuestionTypeSchema,
    options: z.array(CohortFormOptionSchema).nullable(),
    mandatory: z.boolean(),
    userPropertyId: z.string().uuid().nullable(),
  })
  .transform(data => {
    if (Array.isArray(data.options) && data.options.length === 0) {
      data.options = null;
    }
    return data;
  })
  .superRefine(
    (
      request: {type: CohortFormQuestionType; options: Array<CohortFormOption> | null},
      ctx: z.RefinementCtx
    ) => {
      if (request.type !== 'select' && request.options !== null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'options must be null if type is not select',
          path: ['options'],
        });
      }
      if (request.type === 'select' && request.options === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'options must be an array if type is select',
          path: ['options'],
        });
      }
    }
  );
export type CohortFormQuestion = z.infer<typeof CohortFormQuestionSchema>;

export const CohortFormConfigSchema = z.object({
  description: LocalizedRichTextSchema,
  questions: z.array(CohortFormQuestionSchema).min(1),
});
export type CohortFormConfig = z.infer<typeof CohortFormConfigSchema>;

export const CohortFormAnswerSchema = z.union([
  z.boolean(),
  z.number(),
  z.date(),
  z.string(),
  z.null(),
]);

export type CohortFormAnswer = z.infer<typeof CohortFormAnswerSchema>;
