import {z} from 'zod';

export const CohortVideoMediaConfigSchema = z.object({
  fileKey: z.string(),
});
export type CohortVideoMediaConfig = z.infer<typeof CohortVideoMediaConfigSchema>;

export type CohortVideoMediaStruct = {
  Kind: 'cohort.video';
  Config: CohortVideoMediaConfig;
};

export const CohortVideoMediaSpec = {
  kind: 'cohort.video',
  configSchema: CohortVideoMediaConfigSchema,
};
