import {DateSchema} from '@cohort/shared/schema/common';
import {VerificationAttemptInputSchema} from '@cohort/wallet-schemas/journeyStepTriggers';
import {z} from 'zod';

export const JourneyParticipationWSchema = z.object({
  id: z.string().uuid(),
  campaignId: z.string().uuid(),
  canBeCompleted: z.boolean(),
  completedAt: DateSchema.nullable(),
  completedJourneyStepIds: z.array(z.string().uuid()),
  isCompleted: z.boolean(),
  rewardClaimed: z.boolean(),
});
export type JourneyParticipationWDto = z.infer<typeof JourneyParticipationWSchema>;

export const JoinCampaignJourneyWSchema = z.object({
  campaignId: z.string().uuid(),
});
export type JoinCampaignJourneyWDto = z.infer<typeof JoinCampaignJourneyWSchema>;

export const VerifyJourneyStepWSchema = z.object({
  journeyStepId: z.string().uuid(),
  journeyStepTriggerId: z.string().uuid(),
  userConnectionId: z.string().uuid().nullable(),
  verificationAttemptInput: VerificationAttemptInputSchema,
});
export type VerifyJourneyStepWDto = z.infer<typeof VerifyJourneyStepWSchema>;
