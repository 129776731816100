import {cn} from '@cohort/shared-frontend/utils/classNames';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {Colors} from '@cohort/wallet/lib/Theme';
import {Listbox, Transition} from '@headlessui/react';
import {CaretUpDown, Check} from '@phosphor-icons/react';
import {Fragment, useCallback, useEffect, useState} from 'react';

export type SelectOption = {
  value: string;
  label: string;
};

type DropDownInputProps = {
  value: SelectOption;
  label?: string;
  options: SelectOption[];
  onChange?: (selected: SelectOption) => void;
  onBlur?: (selected: SelectOption) => void;
  className?: string;
  mandatory?: boolean;
  error?: JSX.Element;
};

export const DropDownInput = ({
  value,
  label,
  options,
  onChange,
  onBlur,
  className,
  mandatory,
  error,
}: DropDownInputProps): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState(value);

  const {backgroundColor, accentColor, hasDarkAccent} = useThemeContext();

  useEffect(() => setSelectedOption(value), [value]);

  const handleChange = useCallback(
    (selected: SelectOption) => {
      setSelectedOption(selected);
      onChange?.(selected);
    },
    [onChange]
  );

  const onLeave = useCallback(() => onBlur?.(selectedOption), [onBlur, selectedOption]);

  return (
    <Listbox value={selectedOption} onChange={handleChange}>
      {({open}) => (
        <div className={cn('flex flex-col', className)}>
          {label !== undefined && (
            <Listbox.Label className={cn('block text-sm font-medium text-[--xps-color]')}>
              {label}
              {mandatory && <span>*</span>}
            </Listbox.Label>
          )}
          <div className="relative mt-1">
            <Listbox.Button
              data-testid="select-input"
              className="relative min-h-9 w-full cursor-default rounded-md border bg-[--xps-input-background-color] py-2 pl-3 pr-10 text-left [border-color:--xps-input-border-color] focus:outline-none focus:ring-1 focus:ring-[--xps-input-active-border-color] focus:ring-offset-0 focus:[border-color:--xps-input-active-border-color] sm:text-sm"
              style={{
                borderRadius: 'var(--xps-input-border-radius)',
              }}
            >
              <span className="flex items-center">
                <span className="ml-2 block truncate">{selectedOption.label}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <CaretUpDown className="h-5 w-5" aria-hidden="true" style={{color: accentColor}} />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={onLeave}
            >
              <Listbox.Options className="z-10 mt-1 w-full overflow-auto rounded-md border text-base [border-color:--xps-input-border-color] focus:outline-none sm:text-sm">
                {options.map((option, index) => (
                  <Listbox.Option
                    key={option.value}
                    data-testid={`select-input-option-${index}`}
                    className={({active}) =>
                      cn('relative select-none', active ? 'cursor-pointer' : 'cursor-default')
                    }
                    value={option}
                  >
                    {({selected, active}) => (
                      <div
                        className="border-b py-2 pl-3 pr-9 [border-color:--xps-input-border-color]"
                        style={{
                          backgroundColor: active ? accentColor : backgroundColor,
                          ...(active && {color: hasDarkAccent ? Colors.White : Colors.Black}),
                        }}
                      >
                        <div className="flex items-center">
                          <span
                            className={cn(
                              selected ? 'font-semibold' : 'font-normal',
                              'ml-3 block truncate'
                            )}
                          >
                            {option.label}
                          </span>
                          {selected && (
                            <span
                              className={cn(
                                active ? 'text-white' : 'text-blue-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <Check
                                className="h-5 w-5"
                                aria-hidden="true"
                                style={{color: accentColor}}
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
          {error}
        </div>
      )}
    </Listbox>
  );
};
DropDownInput.displayName = 'DropDownInput';
