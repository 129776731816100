import {
  PrivateContentPerkContentTypeSchema,
  RaffleInstanceStatusSchema,
} from '@cohort/shared/schema/common/perks';
import {PerkWSchema} from '@cohort/wallet-schemas/perk';
import {z} from 'zod';

export const PerkAccessWSchema = z.object({
  id: z.string().uuid(),
  canBeUsed: z.boolean(),
  reachedMaxUsages: z.boolean(),
  usagesCount: z.number().int().min(0),
  usagesLeft: z.number().int().min(0).nullable(),
  statusMessage: z.string(),
  raffleStatus: RaffleInstanceStatusSchema.nullable(),
  data: z.unknown().nullable(),
  privateContent: z.string().nullable(),
  privateContentThumbnailFileKey: z.string().nullable(),
  privateContentFileType: PrivateContentPerkContentTypeSchema.nullable(),
});
export type PerkAccessWDto = z.infer<typeof PerkAccessWSchema>;

export const PerkSpaceViewWSchema = z.object({
  perk: PerkWSchema,
  accesses: z.array(PerkAccessWSchema),
});
export type PerkSpaceViewWDto = z.infer<typeof PerkSpaceViewWSchema>;

export const PerkPrivateContentUrlWSchema = z.object({
  url: z.string().url(),
});
export type PerkPrivateContentUrlWDto = z.infer<typeof PerkPrivateContentUrlWSchema>;
