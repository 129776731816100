import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortUsernameChangedPropertiesSchema = z.object({
  memberHandle: z.string(),
});
export type CohortUsernameChangedProperties = z.infer<typeof CohortUsernameChangedPropertiesSchema>;

export type CohortUsernameChangedEventStruct = UserEventStruct<
  'cohort.user.username-changed',
  keyof CohortUsernameChangedProperties,
  CohortUsernameChangedProperties,
  CohortUsernameChangedProperties
>;

export const CohortUsernameChangedEventSpec: UserEventSpec<CohortUsernameChangedEventStruct> = {
  id: 'cohort.user.username-changed',
  description: 'Event Triggerred when a user changes their username',
  propertiesSchema: CohortUsernameChangedPropertiesSchema,
  exposedPropertiesSchema: CohortUsernameChangedPropertiesSchema,
  resources: {},
  rulesEngineConfig: {
    isVisible: false,
    exposedFields: [],
  },
};
