import {cn} from '@cohort/shared-frontend/utils/classNames';
import React, {useState} from 'react';

type SpinnerProps = {
  color?: string;
  height?: string;
  width?: string;
};

const Spinner: React.FC<SpinnerProps> = ({color, height, width}): React.ReactElement => {
  const [textColor] = useState(() => {
    if (color) {
      return color;
    }
    const textColor = getComputedStyle(document.documentElement).getPropertyValue('--xps-color');

    return textColor;
  });

  return (
    <div data-testid="spinner" role="status">
      <svg
        className={cn(
          'animate-spin',
          height !== undefined ? height : 'h-10',
          width !== undefined ? width : 'w-10'
        )}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        color={textColor}
      >
        <circle
          className="opacity-20"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-100"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default Spinner;
