import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

export const YoutubeLikeTriggerConfigSchema = z.object({
  videoId: z.string(),
  videoTitle: z.string(),
});
export type YoutubeLikeTriggerConfig = z.infer<typeof YoutubeLikeTriggerConfigSchema>;

export const YoutubeLikeVerificationAttemptDataSchema = z.object({
  channelId: z.string(),
  channelTitle: z.string(),
  channelHandle: z.string().nullable(),
  videoId: z.string(),
  liked: z.boolean(),
});
export type YoutubeLikeVerificationAttemptData = z.infer<
  typeof YoutubeLikeVerificationAttemptDataSchema
>;

export type YoutubeLikeTriggerStruct = TriggerIntegrationStruct<
  'youtube.like',
  YoutubeLikeTriggerConfig,
  YoutubeLikeVerificationAttemptData
>;
export const YoutubeLikeTriggerSpec: TriggerIntegrationSpec<YoutubeLikeTriggerStruct> = {
  id: 'youtube.like' as const,
  type: 'verification',
  configurableMaxAttempts: false,
  configSchema: YoutubeLikeTriggerConfigSchema,
  verificationAttemptDataSchema: YoutubeLikeVerificationAttemptDataSchema,
  verificationAttemptInputSchema: z.object({}),
  requiresMerchantConnection: true,
};
