import Spinner from '@cohort/shared-frontend/components/Spinner';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {isDark} from '@cohort/shared-frontend/utils/isDark';
import useClickTracker from '@cohort/wallet/hooks/clickTracker';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';
import React, {Fragment} from 'react';

export type BtnTextTransform = 'none' | 'uppercase' | 'lowercase' | 'capitalize';

export type BaseButtonProps = JSX.IntrinsicElements['button'] & {
  text: JSX.Element | string;
  testId?: string;
  icon?: JSX.Element;
  size?: 'default' | 'small' | 'large';
  isLoading?: boolean;
  tracking: TrackingConfig;
};

export const BaseButton: React.FC<BaseButtonProps> = ({
  text,
  testId,
  icon,
  children,
  size = 'default',
  isLoading,
  className,
  tracking,
  ...props
}): React.ReactElement => {
  const {accentColor} = useThemeContext();
  const trackingCallback = useClickTracker(tracking.namespace, tracking.metadata);
  const hasDarkAccent = isDark(accentColor);

  return (
    <button
      data-testid={testId}
      className={cn(
        'inline-flex items-center justify-center rounded-lg border px-4 py-3 text-sm font-medium focus:outline-none',
        props.disabled && 'cursor-not-allowed text-opacity-50',
        size === 'small' && '!py-1.5',
        size === 'large' && '!py-3.5',
        className
      )}
      {...props}
      disabled={props.disabled || isLoading}
      onClick={e => {
        trackingCallback();
        props.onClick?.(e);
      }}
    >
      {isLoading ? (
        <Spinner color={hasDarkAccent ? '#ffffff' : '#000000'} height="h-5" width="w-5" />
      ) : (
        <Fragment>
          {icon !== undefined && <div className="-ml-1 mr-2 h-5 w-5 flex-none">{icon}</div>}
          {text}
        </Fragment>
      )}
    </button>
  );
};
