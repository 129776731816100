import type {ActionSpec, ActionStruct} from '@cohort/shared/apps/app';
import {DiscordRolesSchema} from '@cohort/shared/apps/discord/common';
import z from 'zod';
export const ListRolesInputSchema = z.object({});
export type ListRolesInput = z.infer<typeof ListRolesInputSchema>;
export const ListRolesOutputSchema = z.object({
  roles: DiscordRolesSchema,
});
export type ListRolesOutput = z.infer<typeof ListRolesOutputSchema>;
export type ListRolesActionStruct = ActionStruct<'list-roles', ListRolesInput, ListRolesOutput>;
export const ListRolesActionSpec: ActionSpec<ListRolesActionStruct> = {
  id: 'list-roles',
  inputSchema: ListRolesInputSchema,
  outputSchema: ListRolesOutputSchema,
};
