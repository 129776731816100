export type SvgIconProps<T extends string> = {
  name: T;
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
};

type IconInfo = {
  icon: {viewBox: string; element: JSX.Element};
  color?: string;
};
type BaseSvgIconProps<T extends string> = SvgIconProps<T> & IconInfo;

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function BaseSvgIcon<T extends string>({
  width,
  height,
  color,
  icon,
  style = {},
  ...rest
}: BaseSvgIconProps<T>): JSX.Element | null {
  if (icon.viewBox === undefined || icon.element === undefined) {
    return null;
  }

  const {viewBox, element} = icon;

  return (
    <svg
      viewBox={viewBox}
      width={width}
      height={height}
      style={{fill: color, flexShrink: 0, ...style}}
      {...rest}
    >
      {element}
    </svg>
  );
}
