export enum HoritzontalDirections {
  Right = 1,
  Left = 2,
  Center = 3,
}

export enum VerticalDirections {
  Top = 1,
  Bottom = 2,
}
