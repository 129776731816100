import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';

type SubtitleProps = {
  content: string | JSX.Element;
  icon?: JSX.Element;
  testId?: string;
  className?: string;
};

const Subtitle: React.FC<SubtitleProps> = ({content, icon, testId, className}) => (
  <h3
    data-testid={testId}
    className={cn('w-full break-words', className)}
    dangerouslySetInnerHTML={{__html: content as string}}
  >
    {icon}
  </h3>
);

export default Subtitle;
