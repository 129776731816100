import type {ConnectorStruct} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials, OAuth2Credentials} from '@cohort/shared/apps/oauth';
import {basicOauth2ConnectorSpec} from '@cohort/shared/apps/oauth';
import type {YoutubeProfileProperties} from '@cohort/shared/apps/youtube/common';
import {YoutubeProfilePropertiesSchema} from '@cohort/shared/apps/youtube/common';

export type YoutubeUserConnectorStruct = ConnectorStruct<
  'youtube',
  OAuth2AppCredentials,
  OAuth2Credentials,
  YoutubeProfileProperties,
  null
>;

export const YoutubeUserConnectorSpec = basicOauth2ConnectorSpec<YoutubeUserConnectorStruct>(
  'youtube',
  YoutubeProfilePropertiesSchema
);
