import {TriggerIntegrationIdSchema} from '@cohort/shared/apps';
import {JourneyStepOrchestrationSchema} from '@cohort/shared/schema/common/journeyStep';
import {z} from 'zod';

export const JourneyStepTriggerWSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  triggerIntegrationId: TriggerIntegrationIdSchema,
  triggerIntegrationConfig: z.unknown(),
  connectionId: z.string().uuid().nullable(),
  verificationAttemptsLeft: z.number().nullable(),
});
export type JourneyStepTriggerWDto = z.infer<typeof JourneyStepTriggerWSchema>;

const JourneyStepAvailabilitySchema = z.union([
  z.object({locked: z.literal(false)}),
  z.object({
    locked: z.literal(true),
    reason: z.union([z.literal('start-date'), z.literal('pre-required-steps')]),
  }),
]);

export const JourneyStepWSchema = z.object({
  id: z.string().uuid(),
  triggers: JourneyStepTriggerWSchema.array(),
  title: z.string(),
  description: z.string(),
  icon: z.string().emoji(),
  isCompleted: z.boolean(),
  availability: JourneyStepAvailabilitySchema,
  orchestration: JourneyStepOrchestrationSchema,
});
export type JourneyStepWDto = z.infer<typeof JourneyStepWSchema>;
