import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortCohortLeftPropertiesSchema = z.object({
  cohortId: z.string().uuid(),
});
export type CohortCohortLeftProperties = z.infer<typeof CohortCohortLeftPropertiesSchema>;

export type CohortCohortLeftEventStruct = UserEventStruct<
  'cohort.cohort.left',
  keyof CohortCohortLeftProperties,
  CohortCohortLeftProperties,
  CohortCohortLeftProperties
>;

export const CohortCohortLeftEventSpec: UserEventSpec<CohortCohortLeftEventStruct> = {
  id: 'cohort.cohort.left',
  description: 'Event Triggerred when a user leaves a cohort',
  propertiesSchema: CohortCohortLeftPropertiesSchema,
  exposedPropertiesSchema: CohortCohortLeftPropertiesSchema,
  resources: {
    cohortId: 'cohort.cohort',
  },
  rulesEngineConfig: {
    isVisible: true,
    exposedFields: ['cohortId'],
  },
};
