import {HexColorSchema} from '@cohort/shared/schema/common';
import {
  CampaignPricingSchema,
  CampaignTypeSchema,
  PublishedCampaignStatusSchema,
} from '@cohort/shared/schema/common/campaign';
import {CurrencySchema, PricesSchema} from '@cohort/shared/schema/common/currency';
import {DataModeSchema} from '@cohort/shared/schema/common/dataMode';
import {DigitalAssetCollectionWSchema} from '@cohort/wallet-schemas/digitalAssetCollection';
import {JourneyParticipationWSchema} from '@cohort/wallet-schemas/journeyParticipation';
import {JourneyStepWSchema} from '@cohort/wallet-schemas/journeySteps';
import {OwnershipWSchema} from '@cohort/wallet-schemas/ownership';
import {PerkWSchema} from '@cohort/wallet-schemas/perk';
import {PerkSpaceViewWSchema} from '@cohort/wallet-schemas/perkAccess';
import {z} from 'zod';

export const FaqItemWSchema = z.object({
  question: z.string().min(3),
  answer: z.string().min(3),
});
export type FaqItemWDto = z.infer<typeof FaqItemWSchema>;

export const StoreWSchema = z.object({
  title: z.string(),
  imageFileKey: z.string().nullable(),
  animationFileKey: z.string().nullable(),
  accentColorCode: HexColorSchema.nullable(),
  backgroundColorCode: HexColorSchema.nullable(),
  description: z.string().min(3).nullable(),
  defaultCurrency: CurrencySchema.nullable(),
  prices: PricesSchema,
  pricing: CampaignPricingSchema,
  shopUrl: z.string().url(),
  slug: z.string().min(3),
});
export type StoreWDto = z.infer<typeof StoreWSchema>;

export const JourneyWSchema = z.object({
  imageFileKey: z.string().nullable(),
  animationFileKey: z.string().nullable(),
  description: z.string(),
  title: z.string(),
  optInCta: z.string(),
  steps: z.array(JourneyStepWSchema),
  participation: JourneyParticipationWSchema.nullable(),
});
export type JourneyWDto = z.infer<typeof JourneyWSchema>;

export const BaseCampaignWSchema = z.object({
  id: z.string().uuid(),
  status: PublishedCampaignStatusSchema,
  totalSupply: z.number().int().min(1).nullable(),
  availableSupply: z.number().int().min(0).nullable(),
  collections: z.array(DigitalAssetCollectionWSchema),
  perks: z.array(PerkWSchema),
  faqs: z.array(FaqItemWSchema),
  tosFileKey: z.string().nullable(),
  tosUrl: z.string().url().nullable(),
  mode: DataModeSchema,
  type: CampaignTypeSchema,
});
export type BaseCampaignWDto = z.infer<typeof BaseCampaignWSchema>;

export const CampaignStoreWSchema = BaseCampaignWSchema.merge(
  z.object({type: z.literal('store'), store: StoreWSchema})
);
export type CampaignStoreWDto = z.infer<typeof CampaignStoreWSchema>;

export const CampaignJourneyWSchema = BaseCampaignWSchema.merge(
  z.object({type: z.literal('journey'), journey: JourneyWSchema})
);
export type CampaignJourneyWDto = z.infer<typeof CampaignJourneyWSchema>;

export const CampaignAirdropWSchema = BaseCampaignWSchema.merge(
  z.object({type: z.literal('airdrop')})
);
export type CampaignAirdropWDto = z.infer<typeof CampaignAirdropWSchema>;

export const StandaloneJourneyWSchema = z.object({
  campaign: CampaignJourneyWSchema,
  ownerships: z.array(OwnershipWSchema),
  perks: z.array(PerkSpaceViewWSchema),
});
export type StandaloneJourneyWDto = z.infer<typeof StandaloneJourneyWSchema>;
