import type {Language, LocalizedString} from '@cohort/shared/schema/common';
import {DateSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const StravaTriggerIntegrationActivityGroups = [
  'RUNNING',
  'CYCLING',
  'WALKING',
  'SWIMMING',
] as const;
export type StravaTriggerIntegrationActivityGroupsType =
  (typeof StravaTriggerIntegrationActivityGroups)[number];

export const StravaActivityTypes = [
  'AlpineSki',
  'BackcountrySki',
  'Badminton',
  'Canoeing',
  'Crossfit',
  'EBikeRide',
  'Elliptical',
  'EMountainBikeRide',
  'Golf',
  'GravelRide',
  'Handcycle',
  'HighIntensityIntervalTraining',
  'Hike',
  'IceSkate',
  'InlineSkate',
  'Kayaking',
  'Kitesurf',
  'MountainBikeRide',
  'NordicSki',
  'Pickleball',
  'Pilates',
  'Racquetball',
  'Ride',
  'RockClimbing',
  'RollerSki',
  'Rowing',
  'Run',
  'Sail',
  'Skateboard',
  'Snowboard',
  'Snowshoe',
  'Soccer',
  'Squash',
  'StairStepper',
  'StandUpPaddling',
  'Surfing',
  'Swim',
  'TableTennis',
  'Tennis',
  'TrailRun',
  'Velomobile',
  'VirtualRide',
  'VirtualRow',
  'VirtualRun',
  'Walk',
  'WeightTraining',
  'Wheelchair',
  'Windsurf',
  'Workout',
  'Yoga',
] as const;
type StravaTriggerIntegrationAvailableActivityType = (typeof StravaActivityTypes)[number];

export const StravaTriggerIntegrationActivityTypesMappedByGroups: Record<
  StravaTriggerIntegrationActivityGroupsType,
  StravaTriggerIntegrationAvailableActivityType[]
> = {
  RUNNING: ['Run', 'TrailRun', 'VirtualRun', 'Wheelchair'],
  CYCLING: [
    'EBikeRide',
    'Elliptical',
    'EMountainBikeRide',
    'GravelRide',
    'Handcycle',
    'MountainBikeRide',
    'Ride',
    'Velomobile',
    'VirtualRide',
  ],
  WALKING: ['Hike', 'Snowshoe', 'Wheelchair', 'Walk'],
  SWIMMING: ['Swim'],
};

export const StravaActivityGroupsTranslations: Record<
  StravaTriggerIntegrationActivityGroupsType,
  LocalizedString
> = {
  RUNNING: {en: 'Running', fr: 'Course à pied', es: 'Correr'},
  CYCLING: {en: 'Cycling', fr: 'Cyclisme', es: 'Ciclismo'},
  WALKING: {en: 'Walking', fr: 'Marche à pied', es: 'Caminata'},
  SWIMMING: {en: 'Swimming', fr: 'Natation', es: 'Natación'},
};

// Translations taken from the official Strava website
export const StravaActivityTypesTranslations: Record<StravaActivityTypesType, LocalizedString> = {
  AlpineSki: {en: 'Alpine Ski', fr: 'Ski alpin', es: 'Esquí alpino'},
  BackcountrySki: {en: 'Backcountry Ski', fr: 'Ski de randonnée', es: 'Esquí de montaña'},
  Badminton: {en: 'Badminton', fr: 'Badminton', es: 'Bádminton'},
  Canoeing: {en: 'Canoe', fr: 'Canoë', es: 'Piragüismo'},
  Crossfit: {en: 'Crossfit', fr: 'Crossfit', es: 'Crossfit'},
  EBikeRide: {en: 'E-Bike Ride', fr: 'Vélo électrique', es: 'Bicicleta eléctrica'},
  EMountainBikeRide: {
    en: 'E-Mountain Bike Ride',
    fr: 'Sortie en VTT électrique',
    es: 'Bicicleta de montaña eléctrica',
  },
  Elliptical: {en: 'Elliptical', fr: 'Elliptique', es: 'Elíptica'},
  Golf: {en: 'Golf', fr: 'Golf', es: 'Golf'},
  GravelRide: {en: 'Gravel Ride', fr: 'Sortie en gravel', es: 'Gravel'},
  Handcycle: {en: 'Handcycle', fr: 'Handbike', es: 'Handbike'},
  HighIntensityIntervalTraining: {en: 'HIIT', fr: 'HIIT', es: 'HIIT'},
  Hike: {en: 'Hike', fr: 'Randonnée', es: 'Senderismo'},
  IceSkate: {en: 'Ice Skate', fr: 'Patin à glace', es: 'Patinaje sobre hielo'},
  InlineSkate: {en: 'Inline Skate', fr: 'Roller', es: 'Patinaje en línea'},
  Kayaking: {en: 'Kayaking', fr: 'Kayak', es: 'Kayak'},
  Kitesurf: {en: 'Kitesurf', fr: 'Kitesurf', es: 'Kitesurf'},
  MountainBikeRide: {
    en: 'Mountain Bike Ride',
    fr: 'Sortie en VTT',
    es: 'Paseo en bicicleta de montaña',
  },
  NordicSki: {en: 'Nordic Ski', fr: 'Ski nordique', es: 'Esquí nórdico'},
  Pickleball: {en: 'Pickleball', fr: 'Pickleball', es: 'Pickleball'},
  Pilates: {en: 'Pilates', fr: 'Pilates', es: 'Pilates'},
  Racquetball: {en: 'Racquetball', fr: 'Racquetball', es: 'Racquetball'},
  Ride: {en: 'Ride', fr: 'Vélo', es: 'Paseo'},
  RockClimbing: {en: 'Rock Climb', fr: 'Escalade', es: 'Escalada en roca'},
  RollerSki: {en: 'Roller Ski', fr: 'Ski à roulettes', es: 'Esquí sobre ruedas'},
  Rowing: {en: 'Rowing', fr: 'Aviron', es: 'Remo'},
  Run: {en: 'Run', fr: 'Course à pied', es: 'Correr'},
  Sail: {en: 'Sail', fr: 'Voile', es: 'Navegar'},
  Skateboard: {en: 'Skateboard', fr: 'Skateboard', es: 'Monopatín'},
  Snowboard: {en: 'Snowboard', fr: 'Snowboard', es: 'Snowboard'},
  Snowshoe: {en: 'Snowshoe', fr: 'Raquettes', es: 'Raquetas de nieve'},
  Soccer: {en: 'Football (Soccer)', fr: 'Football', es: 'Fútbol'},
  Squash: {en: 'Squash', fr: 'Squash', es: 'Squash'},
  StairStepper: {en: 'Stair-Stepper', fr: "Simulateur d'escalier", es: 'Simulador de escaleras'},
  StandUpPaddling: {en: 'Stand Up Paddling', fr: 'Stand up paddle', es: 'Paddle surf'},
  Surfing: {en: 'Surfing', fr: 'Surf', es: 'Surf'},
  Swim: {en: 'Swim', fr: 'Natation', es: 'Nadar'},
  TableTennis: {en: 'Table Tennis', fr: 'Tennis de table', es: 'Tenis de mesa'},
  Tennis: {en: 'Tennis', fr: 'Tennis', es: 'Tenis'},
  TrailRun: {en: 'Trail Run', fr: 'Course en sentier', es: 'Carrera de sendero'},
  Velomobile: {en: 'Velomobile', fr: 'Vélomobile', es: 'Velomóvil'},
  VirtualRide: {en: 'Virtual Ride', fr: 'Sortie virtuelle', es: 'Paseo virtual'},
  VirtualRow: {en: 'Virtual Row', fr: 'Rameur virtuel', es: 'Remo virtual'},
  VirtualRun: {en: 'Virtual Run', fr: 'Course virtuelle', es: 'Carrera virtual'},
  Walk: {en: 'Walk', fr: 'Marche', es: 'Caminar'},
  WeightTraining: {
    en: 'Weight Training',
    fr: 'Entraînement aux poids',
    es: 'Entrenamiento de pesas',
  },
  Wheelchair: {en: 'Wheelchair', fr: 'Course en fauteuil', es: 'Silla de ruedas'},
  Windsurf: {en: 'Windsurf', fr: 'Windsurf', es: 'Windsurf'},
  Workout: {en: 'Workout', fr: 'Entraînement', es: 'Entrenamiento'},
  Yoga: {en: 'Yoga', fr: 'Yoga', es: 'Yoga'},
};

export const StravaActivityGroupsSchema = z.enum(StravaTriggerIntegrationActivityGroups);
export type StravaActivityGroupsType = z.infer<typeof StravaActivityGroupsSchema>;

export const StravaActivityTypesSchema = z.enum(StravaActivityTypes);
export type StravaActivityTypesType = z.infer<typeof StravaActivityTypesSchema>;

export const StravaActivitySchema = z.object({
  id: z.number(),
  stravaUserId: z.number(),
  averageSpeedMetersPerSecond: z.number(),
  distanceMeters: z.number(),
  durationSeconds: z.number(),
  name: z.string(),
  startDate: DateSchema,
  totalElevationGainInMeters: z.number(),
  type: StravaActivityTypesSchema,
});
export type StravaActivity = z.infer<typeof StravaActivitySchema>;

export function getStravaActivityGroupLabel(
  activityGroup: StravaActivityGroupsType,
  language: Language
): string {
  return (
    StravaActivityGroupsTranslations[activityGroup][language] ??
    StravaActivityGroupsTranslations[activityGroup].en ??
    activityGroup
  );
}
export function getStravaActivityTypeLabel(
  activityType: StravaActivityTypesType,
  language: Language
): string {
  return (
    StravaActivityTypesTranslations[activityType][language] ??
    StravaActivityTypesTranslations[activityType].en ??
    activityType
  );
}
