import {PerkIntegrationIdSchema} from '@cohort/shared/apps';
import type {PerkIntegrationStruct} from '@cohort/shared/apps/app';
import {DateSchema} from '@cohort/shared/schema/common';
import {
  PerkStatusSchema,
  PerkTypeSchema,
  PrivateContentPerkContentTypeSchema,
} from '@cohort/shared/schema/common/perks';
import {z} from 'zod';

const PerkIntegrationWSchema = z.object({
  perkIntegrationId: PerkIntegrationIdSchema,
  connectionId: z.string().uuid().nullable(),
  config: z.unknown(),
  state: z.unknown(),
});
export type PerkIntegrationWDto = z.infer<typeof PerkIntegrationWSchema>;
export type ResolvedPerkIntegrationWDto<P extends PerkIntegrationStruct> = PerkIntegrationWDto & {
  perkIntegrationId: P['Id'];
  config: P['Config'];
  state: P['State'];
};

export const PerkWSchema = z.object({
  id: z.string().uuid(),
  createdAt: DateSchema,
  displayName: z.string().min(3),
  internalName: z.string().min(3),
  status: PerkStatusSchema,
  description: z.string().min(3).nullable(),
  bannerFileKey: z.string().nullable(),
  videoFileKey: z.string().nullable(),
  thumbnailFileKey: z.string().nullable(),
  type: PerkTypeSchema,
  integration: PerkIntegrationWSchema.nullable(),
  privateContent: z.string().min(3).nullable(),
  privateContentFileKey: z.string().nullable(),
  privateContentThumbnailFileKey: z.string().nullable(),
  privateContentFileType: PrivateContentPerkContentTypeSchema.nullable(),
  maxAccessesPerUser: z.number().nullable(),
});
export type PerkWDto = z.infer<typeof PerkWSchema>;

export type ResolvedPerkWDto<P extends PerkIntegrationStruct> = PerkWDto & {
  integration: ResolvedPerkIntegrationWDto<P>;
};
