import {DateSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const ResourceListDataTypeSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
  })
);
export type ResourceListDataType = z.infer<typeof ResourceListDataTypeSchema>;

export const UserPropertyNameSchema = z.string().min(1).max(100);

export const UserPropertyDataTypeSchema = z.enum([
  'boolean',
  'date',
  'number',
  'string',
  'string_list',
  'resource_list',
]);
export type UserPropertyDataType = z.infer<typeof UserPropertyDataTypeSchema>;

export const UserPropertyValueSchema = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  DateSchema,
  z.array(z.string()),
  ResourceListDataTypeSchema,
]);

export type UserPropertyValue =
  | string
  | number
  | boolean
  | Date
  | Array<string>
  | ResourceListDataType;
