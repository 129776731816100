import PrimaryButton from '@cohort/wallet/components/button/PrimaryButton';
import {ArrowsClockwise} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const NewVersionToast: React.FC = () => {
  const {t} = useTranslation('components', {keyPrefix: 'toasts.newVersionToast'});

  return (
    <div
      className="flex flex-col items-center"
      style={{
        fontFamily: 'var(--xps-font-family)',
      }}
    >
      <span className="mb-2 text-sm font-medium">{t('title')}</span>
      <PrimaryButton
        onClick={() => window.location.reload()}
        text={t('updateBtn')}
        icon={<ArrowsClockwise className="-mr-1 h-5 w-5" />}
        className="h-10"
        tracking={{namespace: 'toasts.newVersion.update'}}
      />
    </div>
  );
};

export default NewVersionToast;
