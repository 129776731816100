import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {BaseModalProps} from '@cohort/wallet/components/modals/BaseModal';
import usePageViewTracker from '@cohort/wallet/hooks/pageViewTracker';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {Drawer} from 'vaul';

interface DrawerModalProps extends BaseModalProps {
  height?: 'full' | 'fit-content';
}

const DrawerModal = ({
  title,
  subtitle,
  onClose,
  children,
  tracking,
  height = 'full',
}: DrawerModalProps): JSX.Element => {
  const {backgroundColor} = useThemeContext();

  usePageViewTracker(tracking.namespace, tracking.metadata);

  return (
    <Drawer.Root open={true} onClose={onClose}>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 z-50 bg-black/40" />
        <Drawer.Content
          className={cn(
            'fixed bottom-0 left-0 right-0 z-50 rounded-t-xl px-8 pt-4',
            height === 'full' && 'h-[96vh]'
          )}
          style={{backgroundColor: backgroundColor}}
        >
          <div className="grid h-full gap-4 rounded-t-xl [grid-template-rows:min-content_1fr]">
            <div className="flex flex-col gap-2">
              <div className="mx-auto h-1.5 w-12 flex-shrink-0 rounded-full bg-zinc-300" />
              {title}
              {subtitle}
            </div>
            <div className="no-scrollbar overflow-y-auto pb-8">{children}</div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default DrawerModal;
