import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import {Component as DefaultNotFoundPage} from '@cohort/wallet/pages/DefaultNotFoundPage';
import React from 'react';

const NotFoundPage: React.FC = () => {
  const merchant = useMerchantContext();

  return <DefaultNotFoundPage merchant={merchant} />;
};

export const Component = NotFoundPage;
