import type {Currency, Price} from '@cohort/shared/schema/common/currency';
import type {CampaignStoreWDto} from '@cohort/wallet-schemas/campaign';
import {createContext} from 'react';

export type BuyingStep = {
  current: number;
  total: number;
};

export type StoreContextType = {
  campaign: CampaignStoreWDto;
  buyingStep: BuyingStep;
  setBuyingStep: (buyingStep: BuyingStep) => void;
  currency: Currency | null;
  setCurrency: (currency: Currency | null) => void;
  price: Price | null;
};

const StoreContext = createContext<StoreContextType | undefined>(undefined);

export default StoreContext;
