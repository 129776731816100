import {useParams} from 'react-router-dom';

type JourneyIdRoutesProps = {
  children: (journeyId: string) => JSX.Element;
};

const JourneyIdRoutes: React.FC<JourneyIdRoutesProps> = ({children}) => {
  const {journeyId} = useParams();

  if (journeyId === undefined) {
    throw new Error('cannot happen');
  }
  return children(journeyId);
};

export default JourneyIdRoutes;
