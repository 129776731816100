import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortPerkRevokedPropertiesSchema = z.object({
  perkAccessId: z.string().uuid(),
  perkId: z.string().uuid(),
});
export type CohortPerkRevokedProperties = z.infer<typeof CohortPerkRevokedPropertiesSchema>;

export type CohortPerkRevokedEventStruct = UserEventStruct<
  'cohort.perk.revoked',
  keyof CohortPerkRevokedProperties,
  CohortPerkRevokedProperties,
  CohortPerkRevokedProperties
>;

export const CohortPerkRevokedEventSpec: UserEventSpec<CohortPerkRevokedEventStruct> = {
  id: 'cohort.perk.revoked',
  description: 'Event Triggerred when a user PerkAccess is revoked',
  propertiesSchema: CohortPerkRevokedPropertiesSchema,
  exposedPropertiesSchema: CohortPerkRevokedPropertiesSchema,
  resources: {
    perkId: 'cohort.perk',
  },
  rulesEngineConfig: {
    isVisible: false,
    exposedFields: [],
  },
};
