import {useRedirectToCustomLogin} from '@cohort/wallet/hooks/redirectToCustomLogin';
import {getMerchantSpaceLoginRoute, getStoreLoginRoute} from '@cohort/wallet/lib/Pages';
import {useCallback} from 'react';
import {useLocation, useMatch, useNavigate} from 'react-router-dom';

export default function useHandleLoginNavigation(): (cohortRedirect?: string) => void {
  const navigate = useNavigate();
  const location = useLocation();
  const isStore = useMatch('/store/*');
  const {redirectCustomLoginEnabled, redirectToCustomLogin} = useRedirectToCustomLogin();

  const navigateToLogin = useCallback((cohortRedirect?: string) => {
    if (redirectCustomLoginEnabled) {
      redirectToCustomLogin(cohortRedirect);
      return;
    }

    if (!isStore) {
      return navigate({
        pathname: getMerchantSpaceLoginRoute(),
        search: location.search,
      });
    }
    const campaignSlugRegex = /^\/store\/([a-zA-Z0-9-_]+)/u;
    const campaignSlug = location.pathname.match(campaignSlugRegex);

    return navigate({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      pathname: !campaignSlug ? getMerchantSpaceLoginRoute() : getStoreLoginRoute(campaignSlug[1]!),
      search: location.search,
    });
  }, []);

  return navigateToLogin;
}
