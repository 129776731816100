import type {ActionSpec, AppSpec, AppStruct} from '@cohort/shared/apps/app';
import type {MediaSpec} from '@cohort/shared/apps/media';
import type {TriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import {createConnectAccountTriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import type {UserPropertySpec} from '@cohort/shared/apps/userProperty';
import {createBaseAppUserPropertySpec} from '@cohort/shared/apps/userProperty';
import {ListVideosActionSpec} from '@cohort/shared/apps/youtube/actions/listVideos';
import {YoutubeVideoMediaSpec} from '@cohort/shared/apps/youtube/medias/video';
import type {YoutubeMerchantConnectorStruct} from '@cohort/shared/apps/youtube/merchantConnector';
import {YoutubeMerchantConnectorSpec} from '@cohort/shared/apps/youtube/merchantConnector';
import type {YoutubeSyncStruct} from '@cohort/shared/apps/youtube/sync';
import {YoutubeSyncSpec} from '@cohort/shared/apps/youtube/sync';
import {YoutubeCommentTriggerSpec} from '@cohort/shared/apps/youtube/triggers/comment';
import {YoutubeLikeTriggerSpec} from '@cohort/shared/apps/youtube/triggers/like';
import {YoutubeSubscribeTriggerSpec} from '@cohort/shared/apps/youtube/triggers/subscribe';
import type {YoutubeUserConnectorStruct} from '@cohort/shared/apps/youtube/userConnector';
import {YoutubeUserConnectorSpec} from '@cohort/shared/apps/youtube/userConnector';
import {YoutubeIsSubscriberUserPropertySpec} from '@cohort/shared/apps/youtube/userProperties/isSubscriber';

export const YOUTUBE_APP_ID = 'youtube' as const;
export const YOUTUBE_APP_NAME = 'Youtube' as const;

export const YoutubeMediaSpecs = [
  YoutubeVideoMediaSpec,
] as const satisfies ReadonlyArray<MediaSpec>;
type YoutubeMediaIds = (typeof YoutubeMediaSpecs)[number]['kind'];

export const YoutubeUserPropertiesSpecs = [
  ...createBaseAppUserPropertySpec(YOUTUBE_APP_ID, YOUTUBE_APP_NAME),
  YoutubeIsSubscriberUserPropertySpec,
  {
    id: 'youtube.channel-title',
    dataType: 'string',
    name: 'Youtube Channel Title',
    scope: 'profile',
    profileKey: 'channelTitle',
  },
  {
    id: 'youtube.channel-handle',
    dataType: 'string',
    name: 'Youtube Channel Handle',
    scope: 'profile',
    profileKey: 'channelHandle',
  },
  {
    id: 'youtube.subscribers-count',
    dataType: 'number',
    name: 'Youtube Number of Subscribers',
    scope: 'profile',
    profileKey: 'subscribersCount',
  },
  {
    id: 'youtube.videos-count',
    dataType: 'number',
    name: 'Youtube Number of Videos',
    scope: 'profile',
    profileKey: 'videosCount',
  },
  {
    id: 'youtube.views-count',
    dataType: 'number',
    name: 'Youtube Number of Views',
    scope: 'profile',
    profileKey: 'viewsCount',
  },
  {
    id: 'youtube.is-verified',
    dataType: 'boolean',
    name: 'Youtube Is Verified',
    scope: 'profile',
    profileKey: 'isVerified',
  },
  {
    id: 'youtube.is-monetized',
    dataType: 'boolean',
    name: 'Youtube Has Monetized Channel',
    scope: 'profile',
    profileKey: 'isMonetized',
  },
] as const satisfies ReadonlyArray<UserPropertySpec>;
type YoutubeUserPropertyIds = (typeof YoutubeUserPropertiesSpecs)[number]['id'];

export const YoutubeActionSpecs = [
  ListVideosActionSpec,
] as const satisfies ReadonlyArray<ActionSpec>;
type YoutubeActionIds = (typeof YoutubeActionSpecs)[number]['id'];

export const YoutubeTriggerSpecs = [
  createConnectAccountTriggerIntegrationSpec(YOUTUBE_APP_ID),
  YoutubeCommentTriggerSpec,
  YoutubeLikeTriggerSpec,
  YoutubeSubscribeTriggerSpec,
] as const satisfies ReadonlyArray<TriggerIntegrationSpec>;
type YoutubeTriggerIds = (typeof YoutubeTriggerSpecs)[number]['id'];

export type YoutubeAppStruct = AppStruct<
  'youtube',
  YoutubeMerchantConnectorStruct,
  YoutubeUserConnectorStruct,
  YoutubeSyncStruct,
  YoutubeActionIds,
  never,
  never,
  YoutubeTriggerIds,
  never,
  YoutubeUserPropertyIds,
  never,
  YoutubeMediaIds
>;

export const YoutubeAppSpec: AppSpec<YoutubeAppStruct> = {
  id: YOUTUBE_APP_ID,
  name: YOUTUBE_APP_NAME,
  merchantConnector: YoutubeMerchantConnectorSpec,
  userConnector: YoutubeUserConnectorSpec,
  syncSpec: YoutubeSyncSpec,
  actionSpecs: YoutubeActionSpecs,
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: [],
  triggerIntegrationSpecs: YoutubeTriggerSpecs,
  userEventSpecs: [],
  userPropertySpecs: YoutubeUserPropertiesSpecs,
  resourceSpecs: [],
  mediaSpecs: YoutubeMediaSpecs,
};
