import {isOnSpace} from '@cohort/wallet/lib/Utils';

const spacePrefix = (): string => (isOnSpace() ? '/space' : '');

export const getHomeRoute = (journeyId?: string): string => {
  if (!isOnSpace() && !journeyId) {
    throw new Error('journeyId is required when not on space');
  }
  return isOnSpace() ? '/space' : `/journeys/${journeyId}`;
};

export const getBadgesListRoute = (): string => {
  return `/space/badges`;
};

export const getJourneysRoute = (campaignId: string): string => {
  return `/journeys/${campaignId}`;
};

export const getSpaceNotFoundRoute = (): string => {
  return '/space/not-found';
};

export const getJourneyNotFoundRoute = (): string => {
  return '/journeys/not-found';
};

export const getStoreNotFoundRoute = (): string => {
  return '/store/not-found';
};

export const getDefaultLoginRoute = (): string => {
  return '/login';
};

export const getMerchantSpaceLoginRoute = (): string => {
  return `/space/login`;
};

export const getMerchantSpaceRoute = (): string => {
  return `/space/home`;
};

export const getOwnershipDetailRoute = (ownershipId: string, journeyId?: string): string => {
  return `${getHomeRoute(journeyId)}/rewards/ownerships/${ownershipId}`;
};

export const getImportNftRoute = (): string => {
  return `/space/rewards/ownerships/imports/new`;
};

export const getPerkDetailRoute = (perkId: string, journeyId?: string): string => {
  return `${getHomeRoute(journeyId)}/rewards/perks/${perkId}`;
};

export const getPerkUsageRoute = (
  perkId: string,
  perkAccessId: string,
  journeyId?: string
): string => {
  return `${getHomeRoute(journeyId)}/rewards/perks/${perkId}/perk-accesses/${perkAccessId}/use`;
};

export const getNftTransferInvitationLoginRoute = (nfTransferInvitationId: string): string => {
  return `/space/invitations/${nfTransferInvitationId}/login`;
};

export const getNftTransferInvitationRoute = (nfTransferInvitationId: string): string => {
  return `/space/invitations/${nfTransferInvitationId}`;
};

export const getNewNftTransferRoute = (ownershipId: string, journeyId?: string): string => {
  return `${getHomeRoute(journeyId)}/rewards/ownerships/${ownershipId}/transfer/new`;
};

export const getJourneysListRoute = (): string => {
  return `/space/journeys`;
};

export const getJourneyDetailRoute = (journeyId: string): string => {
  return `${spacePrefix()}/journeys/${journeyId}`;
};

export const getJourneyLoginRoute = (journeyId: string): string => {
  return `${spacePrefix()}/journeys/${journeyId}/login`;
};

export const getJourneyTriggerIntegrationUsageRoute = (
  journeyId: string,
  stepId: string,
  triggerId: string
): string => {
  return `${spacePrefix()}/journeys/${journeyId}/steps/${stepId}/triggers/${triggerId}/use`;
};

export const getRewardsListRoute = (filter?: 'perks' | 'digital-assets'): string => {
  return `/space/rewards${filter ? `?filter=${filter}` : ''}`;
};

export const getSpaceSettingsRoute = (): string => {
  return `/space/settings`;
};

export const getRootPaths = (campaignSlug?: string, journeyId?: string): string[] => {
  return [
    getMerchantSpaceRoute(),
    getJourneysListRoute(),
    getRewardsListRoute(),
    getSpaceSettingsRoute(),
    ...(journeyId ? [getJourneysRoute(journeyId)] : []),
    ...(campaignSlug ? [getStoreCampaignRoute(campaignSlug)] : []),
  ];
};

export const getStoreLoginRoute = (campaignSlug: string): string => {
  return `/store/${campaignSlug}/login`;
};

export const getStoreCampaignRoute = (campaignSlug: string): string => {
  return `/store/${campaignSlug}`;
};

export const getStoreCheckoutRoute = (campaignSlug: string): string => {
  return `/store/${campaignSlug}/checkout`;
};

export const getStoreOrderRoute = (campaignSlug: string, orderId: string): string => {
  return `/store/${campaignSlug}/orders/${orderId}`;
};
