import MerchantContext from '@cohort/wallet/components/contexts/MerchantContext';
import {LoadingScreen} from '@cohort/wallet/components/layout/LoadingScreen';
import {useMerchant} from '@cohort/wallet/hooks/api/Merchants';
import {useLDClient} from 'launchdarkly-react-client-sdk';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

type MerchantContextProviderProps = {
  children: React.ReactNode;
};

const MerchantContextProvider: React.FC<MerchantContextProviderProps> = ({children}) => {
  const {data: merchant, isFetched} = useMerchant();
  const ldClient = useLDClient();
  const navigate = useNavigate();
  -useEffect(() => {
    if (isFetched && !merchant) {
      navigate('/not-found', {replace: true});
    }
  }, [isFetched, merchant, navigate]);

  if (merchant === undefined) {
    return <LoadingScreen />;
  }

  if (ldClient !== undefined) {
    ldClient.identify({
      key: merchant.organization.slug,
      kind: 'organization',
    });
  }

  return <MerchantContext.Provider value={merchant}>{children}</MerchantContext.Provider>;
};

export default MerchantContextProvider;
