import {WEBAPP_SUPPORTED_LANGUAGES} from '@cohort/shared/schema/common';
import type {InitOptions} from 'i18next';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

// Default namespace.
export const defaultNs = 'lib';

// Initialize namespaces array with 'components'
const namespaces: string[] = ['common', 'lib', 'utils', 'layouts', 'pages', 'components'];

export const initI18n = (options?: InitOptions): void => {
  // eslint-disable-next-line import/no-named-as-default-member
  i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      debug: true,
      fallbackLng: WEBAPP_SUPPORTED_LANGUAGES,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      ns: namespaces,
      defaultNS: defaultNs,
      resources: {},
      ...options,
    });
};

initI18n();
