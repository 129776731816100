import {DateSchema, EmailSchema} from '@cohort/shared/schema/common';
import {
  BlockchainNameSchema,
  NetworkSchema,
  TokenStandardSchema,
} from '@cohort/shared/schema/common/blockchain';
import {
  OwnershipObtentionTypeSchema,
  OwnershipStatusSchema,
} from '@cohort/shared/schema/common/ownerships';
import {DigitalAssetCollectionWSchema} from '@cohort/wallet-schemas/digitalAssetCollection';
import {utils} from 'ethers';
import {z} from 'zod';

const DigitalAssetTransferStatusWSchema = z.enum(['queued', 'processing', 'success', 'failed']);

export const DigitalAssetTransferJobWSchema = z.object({
  id: z.string().uuid(),
  recipientAddress: z.string(),
  status: DigitalAssetTransferStatusWSchema,
});
export type DigitalAssetTransferJobWDto = z.infer<typeof DigitalAssetTransferJobWSchema>;

export const CreateDigitalAssetTransferWSchema = DigitalAssetTransferJobWSchema.omit({
  id: true,
  status: true,
});
export type CreateDigitalAssetTransferWDto = z.infer<typeof CreateDigitalAssetTransferWSchema>;

const NftWSchema = z.object({
  smartContractAddress: z.string(),
  network: NetworkSchema,
  tokenId: z.number().int().min(0),
  blockExplorerUrl: z.string().url().nullable(),
  openseaUrl: z.string().url().nullable(),
  tokenStandard: TokenStandardSchema,
  blockchainName: BlockchainNameSchema,
});
export type NftWDto = z.infer<typeof NftWSchema>;

const DigitalAssetWSchema = z.object({
  id: z.string().uuid(),
  imageFileKey: z.string(),
  animationFileKey: z.string().nullable(),
  shareImageUrl: z.string().url().nullable(),
  tokenId: z.number().int().min(0).nullable(),
  collection: DigitalAssetCollectionWSchema,
  nft: NftWSchema.nullable(),
});
export type DigitalAssetWDto = z.infer<typeof DigitalAssetWSchema>;

const DigitalAssetTransferInvitationStatusWSchema = z.enum([
  'active',
  'accepted',
  'cancelled',
  'expired',
]);

export const DigitalAssetTransferInvitationWSchema = z.object({
  id: z.string().uuid(),
  collection: DigitalAssetCollectionWSchema,
  digitalAssetImageFileKey: z.string(),
  animationFileKey: z.string().nullable(),
  ownershipId: z.string().uuid(),
  senderEmail: EmailSchema,
  recipientEmail: EmailSchema,
  message: z.string().nullable(),
  status: DigitalAssetTransferInvitationStatusWSchema,
  expiresAt: DateSchema,
});
export type DigitalAssetTransferInvitationWDto = z.infer<
  typeof DigitalAssetTransferInvitationWSchema
>;

export const OwnershipWSchema = z.object({
  id: z.string().uuid(),
  status: OwnershipStatusSchema,
  digitalAsset: DigitalAssetWSchema,
  obtentionType: OwnershipObtentionTypeSchema,
  canBeTransferred: z.boolean(),
  transfer: DigitalAssetTransferJobWSchema.nullable(),
  createdAt: DateSchema,
  activeDigitalAssetTransferInvitation: DigitalAssetTransferInvitationWSchema.nullable(),
});
export type OwnershipWDto = z.infer<typeof OwnershipWSchema>;

export const QrCodeTokenWSchema = z.object({
  token: z.string(),
});
export type QrCodeTokenWDto = z.infer<typeof QrCodeTokenWSchema>;

export const TransferOwnershipWDataSchema = z.object({
  validationCode: z.string().nullable(),
});

const RecipientAddressOrEmailRefinement = (
  request: {
    recipientAddress?: string;
    recipientEmail?: string;
  },
  ctx: z.RefinementCtx
): {message: string; path: string[]} | boolean => {
  const {recipientAddress, recipientEmail} = request;

  if (recipientAddress === undefined && recipientEmail === undefined) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'One of the following 2 options must be provided for identifying the recipient: recipientAddress, recipientEmail',
      path: ['recipientAddress', 'recipientEmail'],
    });
    return false;
  }
  if (recipientAddress !== undefined && recipientEmail !== undefined) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'Only one of the following 2 options must be provided for identifying the recipient: recipientAddress, recipientEmail',
      path: ['recipientAddress', 'recipientEmail'],
    });
    return false;
  }
  return true;
};

export const RequestOwnershipTransferDataWSchema = z
  .object({
    recipientEmail: EmailSchema,
    senderMessage: z.string(),
    recipientAddress: z.string().refine(value => utils.isAddress(value), {
      message: 'Invalid recipientAddress',
    }),
  })
  .partial()
  .superRefine(RecipientAddressOrEmailRefinement);
export type RequestOwnershipTransferDataWDto = z.infer<typeof RequestOwnershipTransferDataWSchema>;

export const ConfirmTransferWSchema = z
  .object({
    ownership: OwnershipWSchema,
    recipientAddress: z.string().optional(),
    recipientEmail: EmailSchema.optional(),
  })
  .superRefine(RecipientAddressOrEmailRefinement);
export type ConfirmTransferWDto = z.infer<typeof ConfirmTransferWSchema>;
