import {TurnstileContext} from '@cohort/shared-frontend/contexts/TurnstileContext';
import type {TurnstileInstance} from '@marsidev/react-turnstile';
import {Turnstile} from '@marsidev/react-turnstile';
import {useRef, useState} from 'react';

type TurnstileProviderProps = {
  action: string;
  siteKey: string;
  children: React.ReactNode;
};

const RETRYABLE_ERRORS = /^(crashed|undefined_error|110600|(102|103|104|106|300|600)\d{3})$/u;

export const TurnstileProvider: React.FC<TurnstileProviderProps> = ({
  action,
  siteKey,
  children,
}) => {
  const ref = useRef<TurnstileInstance | null>(null);
  const [token, setToken] = useState<string>();

  const resetToken = (): void => ref.current?.reset();

  return (
    <TurnstileContext.Provider value={{token, resetToken}}>
      <Turnstile
        id="turnstile-global"
        ref={ref}
        siteKey={siteKey}
        options={{size: 'invisible', action}}
        onSuccess={newToken => setToken(newToken)}
        onError={(code?: string | number) => {
          if (code !== undefined && !RETRYABLE_ERRORS.test(String(code))) {
            return false;
          }
          return true;
        }}
      />
      {children}
    </TurnstileContext.Provider>
  );
};
