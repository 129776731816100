import {Card, CardTitleSecondary} from '@cohort/components-xps/components/cards/Card';
import Title from '@cohort/components-xps/components/Title';
import type {
  ActivatedTalonOneCouponAccessData,
  TalonOneCouponConfig,
} from '@cohort/shared/apps/talon-one/perks/coupon';
import {
  ActivatedTalonOneCouponAccessDataSchema,
  TalonOneCouponAccessDataSchema,
} from '@cohort/shared/apps/talon-one/perks/coupon';
import Spinner from '@cohort/shared-frontend/components/Spinner';
import type {PerkUsageComponentProps} from '@cohort/wallet/apps/PerkIntegration';
import PrimaryButton from '@cohort/wallet/components/button/PrimaryButton';
import SecondaryButton from '@cohort/wallet/components/button/SecondaryButton';
import Subtitle from '@cohort/wallet/components/Subtitle';
import {useActivatePerkAccess} from '@cohort/wallet/hooks/api/PerkAccesses';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import {ClipboardText} from '@phosphor-icons/react';
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

type ActiveCouponComponentProps = PerkUsageComponentProps & {
  data: ActivatedTalonOneCouponAccessData;
};

const ActiveCouponComponent: React.FC<ActiveCouponComponentProps> = ({
  perkAccess,
  perk,
  onClose,
  data,
}) => {
  const {couponCode} = data;
  const {storeUrl} = perk.integration?.config as TalonOneCouponConfig;

  const {t} = useTranslation('app-talon-one', {
    keyPrefix: 'perkIntegrations.coupon.perkUsageComponent',
  });
  const [copied, setCopied] = useState(false);

  const onGotoShop = useCallback(() => {
    window.open(storeUrl, '_blank', 'noopener,noreferrer');
  }, [storeUrl]);

  const handleCopy = useCallback(async () => {
    await navigator.clipboard.writeText(couponCode);
    setCopied(true);
    setTimeout(() => setCopied(false), 4000);
  }, [couponCode]);

  return (
    <Fragment>
      <div className="flex flex-col items-center justify-center gap-2 md:flex-row md:justify-start">
        <Card onClick={handleCopy} className="flex w-fit cursor-pointer items-center gap-2">
          <CardTitleSecondary>{couponCode}</CardTitleSecondary>
          <ClipboardText size={16} />
        </Card>
        {copied && <p>{t('copied')}</p>}
      </div>
      <div className="mt-6 flex flex-col gap-4 md:flex-row">
        <PrimaryButton
          onClick={onGotoShop}
          text={t('openShop')}
          tracking={{
            namespace: 'perks.usage',
            metadata: {
              perkIntegrationId: perk.integration?.perkIntegrationId,
              action: 'visitShop',
              perkAccessId: perkAccess.id,
              perkId: perk.id,
            },
          }}
        />
        <SecondaryButton
          onClick={() => {
            onClose();
          }}
          text={t('close')}
          tracking={{
            namespace: 'perks.usage',
            metadata: {
              perkIntegrationId: perk.integration?.perkIntegrationId,
              action: 'close',
              perkAccessId: perkAccess.id,
              perkId: perk.id,
            },
          }}
        />
      </div>
    </Fragment>
  );
};

export const TalonOneCouponPerkUsageComponent: React.FC<PerkUsageComponentProps> = props => {
  const {perk, perkAccess} = props;
  const [data, setData] = useState(TalonOneCouponAccessDataSchema.parse(props.perkAccess.data));
  const merchant = useMerchantContext();
  const {t} = useTranslation('app-talon-one', {
    keyPrefix: 'perkIntegrations.coupon.perkUsageComponent',
  });

  const needsActivation = ActivatedTalonOneCouponAccessDataSchema.safeParse(data).success === false;

  const {data: activatedPerkAccess} = useActivatePerkAccess(perkAccess.id, needsActivation);

  useEffect(() => {
    if (activatedPerkAccess !== undefined) {
      setData(TalonOneCouponAccessDataSchema.parse(activatedPerkAccess.data));
    }
  }, [activatedPerkAccess]);

  return (
    <div className="flex flex-col">
      <div className="gap-y-2 pb-7">
        <Title content={perk.displayName} testId="use-talon-one-coupon-perk-nft-picker-title" />
        <Subtitle
          className="text-center md:text-left"
          testId="use-talon-one-coupon-perk-nft-picker-subtitle"
          content={
            needsActivation ? t('subtitleActivating') : t('subtitle', {brand: merchant.name})
          }
        />
      </div>
      {needsActivation ? (
        <div className="relative my-4 flex justify-center md:justify-start">
          <Spinner height="h-5" width="w-5" />
        </div>
      ) : (
        <ActiveCouponComponent {...props} data={data as ActivatedTalonOneCouponAccessData} />
      )}
    </div>
  );
};
