import Navbar from '@cohort/wallet/components/navigation/NavBar';
import {campaignsKeys} from '@cohort/wallet/hooks/api/Campaign';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import useJourneyContext from '@cohort/wallet/hooks/useJourneyContext';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import {useNavigateWithTransition} from '@cohort/wallet/hooks/useNavigateWithTransition';
import BaseLayout from '@cohort/wallet/layouts/BaseLayout';
import {setAmplitudeMetadata} from '@cohort/wallet/lib/Tracking';
import {useQueryClient} from '@tanstack/react-query';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

const JourneyLayout = (): JSX.Element => {
  const {t} = useTranslation('layouts', {
    keyPrefix: 'journeys.journeyLayout',
  });
  const {journeyId} = useParams();
  const merchant = useMerchantContext();
  const {campaign} = useJourneyContext();
  const navigateWithTransition = useNavigateWithTransition();
  const queryClient = useQueryClient();
  const isLoggedIn = useUserStore(store => store.isLoggedIn);
  const [wasLoggedIn, setWasLoggedIn] = useState(isLoggedIn);

  useEffect(() => {
    setAmplitudeMetadata({merchant, journey: campaign, rootPath: 'journeys'});
  }, [campaign, merchant]);

  // refresh campaign at login
  useEffect(() => {
    if (wasLoggedIn === false && isLoggedIn) {
      setWasLoggedIn(true);
      queryClient.invalidateQueries(campaignsKeys.getJourneyById(merchant.id, campaign.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <BaseLayout
      navbar={
        <Navbar
          logoFileKey={merchant.logoFileKey}
          appTitle={t('journeyTitle', {name: merchant.name})}
          journeyId={journeyId}
          handleBackButtonClick={() => navigateWithTransition(-1, undefined, 'backward')}
        />
      }
    />
  );
};

export default JourneyLayout;
