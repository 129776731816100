import {cn} from '@cohort/shared-frontend/utils/classNames';
import usePageViewTracker from '@cohort/wallet/hooks/pageViewTracker';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';
import {Dialog, Transition} from '@headlessui/react';
import {X} from '@phosphor-icons/react';
import type {MouseEvent} from 'react';
import {Fragment} from 'react';

export type BaseModalProps = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  onClose?: () => void;
  className?: string;
  tracking: TrackingConfig;
  width?: number;
};

export const BaseModal = ({
  title,
  subtitle,
  children,
  onClose,
  className,
  tracking,
  width,
}: BaseModalProps): JSX.Element => {
  const {backgroundColor} = useThemeContext();

  usePageViewTracker(tracking.namespace, tracking.metadata);

  return (
    <Transition.Root appear={true} show={true} as={'div'}>
      <Dialog
        className="relative z-50 cursor-default"
        onClick={(e: MouseEvent) => {
          e.preventDefault();
        }}
        onClose={() => onClose && onClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div tabIndex={0} className="flex h-full items-center justify-center px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                style={{backgroundColor: backgroundColor, ...(width && {width: width})}}
                className={cn(
                  'grid max-h-[var(--modal-max-height)] gap-4 rounded-lg p-8 shadow-xl [grid-template-rows:min-content_1fr]',
                  !width && 'min-w-[50vw]',
                  className
                )}
              >
                <div className="flex flex-col">
                  <div className="flex items-center justify-between">
                    {title}
                    {onClose && <X onClick={onClose} className="ml-auto h-6 w-6 cursor-pointer" />}
                  </div>
                  {subtitle}
                </div>
                <div className="no-scrollbar overflow-y-auto">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default BaseModal;
