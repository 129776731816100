import Spinner from '@cohort/shared-frontend/components/Spinner';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {CheckCircle} from '@phosphor-icons/react';

const LoadingStep: React.FC<{isLoading: boolean; text: string}> = ({isLoading, text}) => (
  <li>
    <h3 className={cn('flex items-center gap-2 text-lg', isLoading && 'text-gray-400')}>
      {!isLoading ? (
        <Spinner height="h-6" width="w-6" />
      ) : (
        <CheckCircle className="h-6 min-h-[1.5rem] w-6 min-w-[1.5rem] text-green-600" />
      )}
      {text}
    </h3>
  </li>
);

export default LoadingStep;
