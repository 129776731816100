import {BaseButton} from '@cohort/wallet/components/button/BaseButton';
import ErrorPageTemplate from '@cohort/wallet/components/ErrorPageTemplate';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import {ErrorBoundary} from '@sentry/react';
import {useTranslation} from 'react-i18next';

type ErrorComponentProps = {
  resetError: () => void;
};

const ErrorComponent: React.FC<ErrorComponentProps> = ({resetError}) => {
  const {t} = useTranslation('components', {keyPrefix: 'errorBoundary'});
  const merchant = useMerchantContext();

  return (
    <ErrorPageTemplate
      title={t('title')}
      description={t('description')}
      merchant={merchant}
      cta={
        <BaseButton
          className="bg-[--cohort-color] text-white"
          text={t('btnBack')}
          onClick={resetError}
          tracking={{namespace: 'errorBoundary.back'}}
        />
      }
    />
  );
};

const ErrorBoundaryComponent = ({children}: {children: React.ReactNode}): JSX.Element => (
  <ErrorBoundary fallback={props => <ErrorComponent {...props} />}>{children}</ErrorBoundary>
);

export default ErrorBoundaryComponent;
