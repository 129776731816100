import {cn} from '@cohort/shared-frontend/utils/classNames';
import useClickTracker from '@cohort/wallet/hooks/clickTracker';
import useSpaceNavigation from '@cohort/wallet/hooks/spaceNavigation';
import {useNavigateWithTransition} from '@cohort/wallet/hooks/useNavigateWithTransition';
import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';
import {GearSix} from '@phosphor-icons/react';
import {isString} from 'remeda';

type NavBarButtonProps = {
  disabled?: boolean;
  isSelected: boolean;
  onClick: () => void;
  content: JSX.Element | string;
  className?: string;
  tracking: TrackingConfig;
};

const NavBarButton: React.FC<NavBarButtonProps> = ({
  isSelected,
  disabled,
  onClick,
  content,
  className,
  tracking,
}): JSX.Element => {
  const trackingCallback = useClickTracker(tracking.namespace, tracking.metadata);

  return (
    <button
      disabled={disabled}
      className={cn(
        'cursor-pointer rounded-lg px-3 py-2 text-sm leading-4',
        isSelected
          ? 'border border-[--xps-navbar-link-active-border-color] text-[--xps-navbar-link-active-color]'
          : 'text-[--xps-navbar-link-color] hover:text-[--xps-navbar-link-hover-color]',
        className
      )}
      style={{
        borderRadius: 'var(--xps-navbar-link-border-radius)',
        backgroundColor: isSelected
          ? 'var(--xps-navbar-link-active-background-color)'
          : 'var(--xps-navbar-link-background-color)',
      }}
      onClick={() => {
        trackingCallback();
        onClick();
      }}
    >
      {isString(content) ? (
        <span className="line-clamp-2" title={content}>
          {content}
        </span>
      ) : (
        content
      )}
    </button>
  );
};

const SpaceNavbarNavigation: React.FC = () => {
  const navigateWithTransition = useNavigateWithTransition();
  const spaceSections = useSpaceNavigation();
  const settingsSectionIdx = spaceSections.findIndex(section => section.id === 'settings');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const settingsSection = spaceSections.splice(settingsSectionIdx, 1)[0]!;

  return (
    <div className="flex w-full justify-between px-4 md:px-8">
      <div className="flex h-full w-full items-center space-x-7">
        {spaceSections.map(section => (
          <NavBarButton
            key={section.id}
            isSelected={section.isSelected}
            disabled={section.disabled}
            onClick={() => {
              navigateWithTransition(section.path);
            }}
            content={section.title}
            tracking={{namespace: `navbar.${section.id}`}}
          />
        ))}
      </div>

      <div className="flex justify-center">
        <div className="my-auto">
          <NavBarButton
            isSelected={settingsSection.isSelected}
            disabled={settingsSection.disabled}
            onClick={() => {
              navigateWithTransition(settingsSection.path);
            }}
            content={
              <div className="flex items-center gap-x-2">
                <GearSix size={20} />
                {settingsSection.title}
              </div>
            }
            tracking={{namespace: 'navbar.settings'}}
          />
        </div>
      </div>
    </div>
  );
};

export default SpaceNavbarNavigation;
