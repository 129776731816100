import Title from '@cohort/components-xps/components/Title';
import type {ConnectorId} from '@cohort/shared/apps';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {App} from '@cohort/wallet/apps';
import type {BaseButtonProps} from '@cohort/wallet/components/button/BaseButton';
import PrimaryButton from '@cohort/wallet/components/button/PrimaryButton';
import SecondaryButton from '@cohort/wallet/components/button/SecondaryButton';
import BaseModal from '@cohort/wallet/components/modals/BaseModal';
import DrawerModal from '@cohort/wallet/components/modals/DrawerModal';
import {useCohortMutation} from '@cohort/wallet/hooks/api/Query';
import {useAppStore} from '@cohort/wallet/hooks/stores/app';
import {useApi} from '@cohort/wallet/hooks/useApi';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {ConnectionsApi} from '@cohort/wallet/lib/Endpoints';
import {Info} from '@phosphor-icons/react';
import {Fragment, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {shallow} from 'zustand/shallow';

type OAuthConnectButtonProps = Omit<BaseButtonProps, 'isLoading' | 'onClick'> & {
  app: App;
  connectorId: ConnectorId;
  xpsRedirectUrl: string;
  existingConnectionId?: string;
};

type DataPolicyModalProps = {
  app: App;
  onClose: () => void;
};

const DataPolicyModal: React.FC<DataPolicyModalProps> = ({app, onClose}) => {
  const {accentColor} = useThemeContext();
  const {isMobile} = useScreenSize();
  const {t} = useTranslation('apps', {keyPrefix: 'oauthConnectButton'});

  const Modal = isMobile ? DrawerModal : BaseModal;
  const ModalContent = (
    <div className="md:max-w-[50vw]">
      <p className="text-sm">
        <Trans
          i18nKey="userDataPolicy"
          ns={`app-${app.spec.id}`}
          components={{
            externalLink: (
              <a
                style={{color: accentColor}}
                className="cursor-pointer"
                target="_blank"
                rel="noreferrer"
              />
            ),
          }}
        />
      </p>
      <SecondaryButton
        className="mt-4 w-full"
        text={t('closeModal')}
        onClick={onClose}
        tracking={{namespace: 'apps.userDataPolicy.close'}}
      />
    </div>
  );

  return (
    <Modal
      title={<Title content={t('userDataPolicyModalTitle')} />}
      tracking={{namespace: 'modals.apps.userDataPolicy'}}
      onClose={onClose}
    >
      {ModalContent}
    </Modal>
  );
};

const OAuthConnectButton: React.FC<OAuthConnectButtonProps> = ({
  app,
  connectorId,
  xpsRedirectUrl,
  existingConnectionId,
  ...props
}) => {
  const [dataPolicyModal, setDataPolicyModal] = useState(false);
  const getAuthorizationUrl = useApi(ConnectionsApi.getOauthAuthorizationUrl);
  const {embedUrl, embedded} = useAppStore(
    store => ({
      embedUrl: store.embedUrl,
      embedded: store.embedded,
    }),
    shallow
  );
  const {mutate: startConnectionFlow, isLoading} = useCohortMutation({
    mutationFn: async () =>
      getAuthorizationUrl({
        connectorId,
        embedUrl: embedUrl ?? null,
        xpsRedirectUrl,
        existingConnectionId,
      }),
    onSuccess: ({authorizationUrl}) =>
      embedded
        ? window.parent.location.replace(authorizationUrl)
        : window.location.replace(authorizationUrl),
  });

  return (
    <Fragment>
      {dataPolicyModal && <DataPolicyModal app={app} onClose={() => setDataPolicyModal(false)} />}
      <div className="flex items-center gap-2">
        {app.withUserDataPolicy && (
          <SecondaryButton
            className={cn(props.className, '!w-auto !px-2 !py-2')}
            text={<Info className="size-5" />}
            onClick={() => setDataPolicyModal(true)}
            tracking={{
              namespace: 'apps.userDataPolicy',
              metadata: {appId: app.spec.id},
            }}
          />
        )}
        <PrimaryButton {...props} isLoading={isLoading} onClick={() => startConnectionFlow()} />
      </div>
    </Fragment>
  );
};

export default OAuthConnectButton;
