import type {ActionSpec, ActionStruct} from '@cohort/shared/apps/app';
import z from 'zod';

export const CreateQrCodeInputSchema = z.object({
  perkAccessId: z.string(),
});
export type CreateQrCodeInput = z.infer<typeof CreateQrCodeInputSchema>;

export const CreateQrCodeOutputSchema = z.object({
  qrCode: z.string(),
  expiration: z.number(),
});
export type CreateQrCodeOutput = z.infer<typeof CreateQrCodeOutputSchema>;

export type CreateQrCodeActionStruct = ActionStruct<
  'create-qr-code',
  CreateQrCodeInput,
  CreateQrCodeOutput
>;
export const CreateQrCodeActionSpec: ActionSpec<CreateQrCodeActionStruct> = {
  id: 'create-qr-code',
  inputSchema: CreateQrCodeInputSchema,
  outputSchema: CreateQrCodeOutputSchema,
};
