import type {InstagramCommentMediaTriggerStruct} from '@cohort/shared/apps/instagram/triggers/commentMedia';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import DetailComponentSection from '@cohort/wallet/components/space/DetailsComponentSection';
import {useTranslation} from 'react-i18next';

const InstagramCommentMediaTriggerIntegrationDetailsComponent: React.FC<
  DetailsComponentProps<InstagramCommentMediaTriggerStruct>
> = ({config}) => {
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'triggerIntegrations.comment-media.detailsComponent',
  });

  return (
    <div className="flex flex-col gap-4">
      <DetailComponentSection title={t('rulesTitle')}>
        <div className="flex flex-row flex-wrap gap-2">
          <p>{t('rulesContent')}</p>
          <a href={config.mediaPermalink} target="_blank" rel="noreferrer">
            {config.mediaPermalink}
          </a>
        </div>
      </DetailComponentSection>
    </div>
  );
};

export default InstagramCommentMediaTriggerIntegrationDetailsComponent;
