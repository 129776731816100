import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortJourneyCompletedPropertiesSchema = z.object({
  campaignId: z.string().uuid(),
  journeyParticipationId: z.string().uuid(),
});
export type CohortJourneyCompletedProperties = z.infer<
  typeof CohortJourneyCompletedPropertiesSchema
>;

export type CohortJourneyCompletedEventStruct = UserEventStruct<
  'cohort.journey.completed',
  keyof CohortJourneyCompletedProperties,
  CohortJourneyCompletedProperties,
  CohortJourneyCompletedProperties
>;

export const CohortJourneyCompletedEventSpec: UserEventSpec<CohortJourneyCompletedEventStruct> = {
  id: 'cohort.journey.completed',
  description: 'Event Triggerred when a user completes a Journey',
  propertiesSchema: CohortJourneyCompletedPropertiesSchema,
  exposedPropertiesSchema: CohortJourneyCompletedPropertiesSchema,
  resources: {
    campaignId: 'cohort.journey',
  },
  rulesEngineConfig: {
    isVisible: true,
    exposedFields: ['campaignId'],
  },
};
