import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortNewJourneyAvailablePropertiesSchema = z.object({
  campaignId: z.string().uuid(),
});
export type CohortNewJourneyAvailableProperties = z.infer<
  typeof CohortNewJourneyAvailablePropertiesSchema
>;

export type CohortNewJourneyAvailableEventStruct = UserEventStruct<
  'cohort.journey.new-journey-available',
  keyof CohortNewJourneyAvailableProperties,
  CohortNewJourneyAvailableProperties,
  CohortNewJourneyAvailableProperties
>;

export const CohortNewJourneyAvailableEventSpec: UserEventSpec<CohortNewJourneyAvailableEventStruct> =
  {
    id: 'cohort.journey.new-journey-available',
    description: 'Event Triggerred when a new Journey is available to a user',
    propertiesSchema: CohortNewJourneyAvailablePropertiesSchema,
    exposedPropertiesSchema: CohortNewJourneyAvailablePropertiesSchema,
    resources: {
      campaignId: 'cohort.journey',
    },
    rulesEngineConfig: {
      isVisible: false,
      exposedFields: [],
    },
  };
