import type {SyncIntegrationSpec, SyncIntegrationStruct} from '@cohort/shared/apps/sync';
import {BaseUserEventExportConfigSchema} from '@cohort/shared/apps/sync';
import {z} from 'zod';

export const TalonOneUserEventExportConfigSchema = BaseUserEventExportConfigSchema.extend({
  customerProfileIdUserPropertyId: z.string().nullable(), // The ID of the user property that contains Talon.One's customer profile ID. If null, all customers will be considered anonymous.
  exportEventsForAnonymousCustomers: z.boolean(), // Whether to export events for customer's that don't have a Talon.One customer ID
});
export type TalonOneUserEventExportConfig = z.infer<typeof TalonOneUserEventExportConfigSchema>;

export type TalonOneSyncStruct = SyncIntegrationStruct<
  never,
  never,
  never,
  TalonOneUserEventExportConfig
>;

export const TalonOneSyncSpec: SyncIntegrationSpec<TalonOneSyncStruct> = {
  contentSyncConfigSchema: null,
  userSyncsConfigSchema: null,
  userSyncsStateSchema: null,
  userEventExportConfigSchema: TalonOneUserEventExportConfigSchema,
};
