import {DEFAULT_ACCENT_COLOR} from '@cohort/shared/schema/common';
import {isDark} from '@cohort/shared-frontend/utils/isDark';
import {BaseButton} from '@cohort/wallet/components/button/BaseButton';
import ErrorPageTemplate from '@cohort/wallet/components/ErrorPageTemplate';
import {Colors} from '@cohort/wallet/lib/Theme';
import {isOnSpace} from '@cohort/wallet/lib/Utils';
import type {MerchantWDto} from '@cohort/wallet-schemas/merchant';
import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getI18n} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

type DefaultNotFoundPageProps = {
  merchant?: MerchantWDto;
};

const DefaultNotFoundPage: React.FC<DefaultNotFoundPageProps> = ({merchant}) => {
  const navigate = useNavigate();
  const {t} = useTranslation('pages', {keyPrefix: 'notFoundPage'});
  const accentColor = merchant ? merchant.accentColorCode ?? DEFAULT_ACCENT_COLOR : undefined;
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  // Load default not found page translations since the global /not-found page is outisde the App initialization
  useEffect(() => {
    const lng = getI18n().language;
    async function loadDefaultNotFoundPageTranslations(): Promise<void> {
      const translationResource = (await import(`../lib/locales/${lng}.json`)).default;

      getI18n().addResourceBundle(lng, 'pages', translationResource.pages);
      setTranslationsLoaded(true);
    }

    if (getI18n().getResource(lng, 'pages', 'notFoundPage') === undefined) {
      loadDefaultNotFoundPageTranslations();
    } else {
      setTranslationsLoaded(true);
    }
  }, []);

  if (!translationsLoaded) {
    return <Fragment />;
  }

  // there is nowhere to fallback if a standalone journey or a store is not found
  const hideHomeButton = !isOnSpace();

  return (
    <ErrorPageTemplate
      title={t('notExist')}
      description={t('sorry')}
      code={404}
      cta={
        !hideHomeButton ? (
          <BaseButton
            style={{
              backgroundColor: accentColor,
              color: accentColor && !isDark(accentColor) ? Colors.Black : Colors.White,
            }}
            text={t('action')}
            onClick={() => navigate('/space/home')}
            tracking={{namespace: 'notfound.back'}}
          />
        ) : (
          <Fragment />
        )
      }
      merchant={merchant}
    />
  );
};

export const Component = DefaultNotFoundPage;
