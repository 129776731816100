import type {ResourceSpec, ResourceStruct} from '@cohort/shared/apps/resource';
import {BaseResourcePropertiesSchema} from '@cohort/shared/apps/resource';
import {z} from 'zod';

export const CohortJourneyResourceSchema = BaseResourcePropertiesSchema.extend({
  id: z.string().uuid(),
});

export type CohortJourneyResourceType = z.infer<typeof CohortJourneyResourceSchema>;

export type CohortJourneyResourceStruct = ResourceStruct<
  'cohort.journey',
  CohortJourneyResourceType
>;

export const CohortJourneyResourceSpec: ResourceSpec<CohortJourneyResourceStruct> = {
  id: 'cohort.journey',
  schema: CohortJourneyResourceSchema,
};
