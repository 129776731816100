import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortJourneyStepCompletedPropertiesSchema = z.object({
  campaignId: z.string().uuid(),
  journeyParticipationId: z.string().uuid(),
  journeyStepCompletionId: z.string().uuid(),
  journeyStepId: z.string().uuid(),
});
export type CohortJourneyStepCompletedProperties = z.infer<
  typeof CohortJourneyStepCompletedPropertiesSchema
>;

export type CohortJourneyStepCompletedEventStruct = UserEventStruct<
  'cohort.journey.step-completed',
  keyof CohortJourneyStepCompletedProperties,
  CohortJourneyStepCompletedProperties,
  CohortJourneyStepCompletedProperties
>;

export const CohortJourneyStepCompletedEventSpec: UserEventSpec<CohortJourneyStepCompletedEventStruct> =
  {
    id: 'cohort.journey.step-completed',
    description: 'Event Triggerred when a user completes a step of a Journey',
    propertiesSchema: CohortJourneyStepCompletedPropertiesSchema,
    exposedPropertiesSchema: CohortJourneyStepCompletedPropertiesSchema,
    resources: {
      campaignId: 'cohort.journey',
    },
    rulesEngineConfig: {
      isVisible: true,
      exposedFields: ['campaignId'],
    },
  };
export default CohortJourneyStepCompletedEventSpec;
