import type {SyncIntegrationSpec, SyncIntegrationStruct} from '@cohort/shared/apps/sync';
import {UserPropertyDataTypeSchema} from '@cohort/shared/schema/common/userProperty';
import {z} from 'zod';

export const SalesforceUserPropertyColumnSchema = z.object({
  columnName: z.string(),
  referenceId: z.string(),
  propertyName: z.string(),
  dataType: UserPropertyDataTypeSchema,
});
export type SalesforceUserPropertyColumn = z.infer<typeof SalesforceUserPropertyColumnSchema>;

export const SalesforceSyncConfigSchema = z.object({
  query: z.string(),
  userPropertyColumns: z.array(SalesforceUserPropertyColumnSchema),
  emailColumn: z.string(),
});
export type SalesforceSyncConfig = z.infer<typeof SalesforceSyncConfigSchema>;

export type SalesforceSyncStruct = SyncIntegrationStruct<
  SalesforceSyncConfig,
  SalesforceSyncState,
  never,
  never
>;

export const SalesforceSyncStateSchema = z.object({
  maxUpdatedAt: z.string().nullable(),
});
export type SalesforceSyncState = z.infer<typeof SalesforceSyncStateSchema>;

export const SalesforceSyncIntegrationSpec: SyncIntegrationSpec<SalesforceSyncStruct> = {
  userSyncsConfigSchema: SalesforceSyncConfigSchema,
  userSyncsStateSchema: SalesforceSyncStateSchema,
  userEventExportConfigSchema: null,
  contentSyncConfigSchema: null,
};
