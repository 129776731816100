import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import {InitParamsWSchema} from '@cohort/wallet-schemas/init';
import {create} from 'zustand';

const isEmbedded = window !== window.parent;

type AppState = {
  authToken?: string;
  customLoginUrl?: string;
  customLoginRedirectParameterName?: string;
  disableLogout?: boolean;
  embedded: boolean;
  embedUrl?: string;
  embedEmail?: string;
  withNavbar: boolean;
  navigationType: 'burger' | 'tabbar';
};

export type AppStore = AppState & {
  initFromSearchParams: (params: URLSearchParams) => Partial<AppState> | undefined;
};

export const useAppStore = create<AppStore>(set => ({
  embedded: isEmbedded,
  withNavbar: true,
  navigationType: 'tabbar',
  initFromSearchParams: params => {
    const searchParams = Object.fromEntries(params.entries());
    const initParams = InitParamsWSchema.safeParse(searchParams);

    if (initParams.success) {
      const parsedParams = initParams.data;
      const newAppState: Partial<AppState> = {
        authToken: parsedParams.authToken,
        customLoginUrl: parsedParams.customLoginUrl,
        customLoginRedirectParameterName: parsedParams.customLoginRedirectParameterName,
        embedUrl: parsedParams.embedUrl,
        embedEmail: parsedParams.embedEmail,
        embedded:
          // providing embedded takes precedence over embedUrl which takes precedence over default value
          parsedParams.embedded !== undefined
            ? parsedParams.embedded
            : // if embedUrl is provided, we assume it's embedded
              parsedParams.embedUrl !== undefined
              ? true
              : isEmbedded,
        disableLogout: parsedParams.disableLogout ?? false,
        withNavbar: parsedParams.navbar ?? true,
        navigationType:
          parsedParams.navigationType && parsedParams.navigationType === 'burger'
            ? 'burger'
            : 'tabbar',
      };

      if (parsedParams.embedEmail || parsedParams.email) {
        useUserStore.getState().setInitEmail(parsedParams.embedEmail ?? parsedParams.email);
      }

      set(newAppState);
      return newAppState;
    }
    return undefined;
  },
}));
