import {DateSchema, EmailSchema, LanguageSchema} from '@cohort/shared/schema/common';
import {MemberHandleSchema} from '@cohort/shared/schema/common/space';
import {z} from 'zod';

export const MembershipPassWSchema = z.object({
  memberHandle: MemberHandleSchema,
  memberId: z.number().int(),
  subscriptionDate: DateSchema,
  blockExplorerUrl: z.string().nullable(),
});
export type MembershipPassWDto = z.infer<typeof MembershipPassWSchema>;

export const WalletWSchema = z.object({
  address: z.string(),
});
export type WalletWDto = z.infer<typeof WalletWSchema>;

export const UserWSchema = z.object({
  id: z.string().uuid(),
  email: EmailSchema,
  lang: LanguageSchema,
  wallet: WalletWSchema,
  membershipPass: MembershipPassWSchema,
  notificationsEnabled: z.boolean(),
});
export type UserWDto = z.infer<typeof UserWSchema>;

export const SendSignInCodeEmailWSchema = z.object({
  email: EmailSchema,
  merchantSlug: z.string(),
});
export type SendSignInCodeEmailWDto = z.infer<typeof SendSignInCodeEmailWSchema>;

export const OtpVerificationInputWSchema = z.object({
  email: EmailSchema,
  merchantSlug: z.string(),
  code: z.string(),
});
export type OtpVerificationInputWDto = z.infer<typeof OtpVerificationInputWSchema>;

export const OtpVerificationOutputWSchema = z.object({
  token: z.string(),
});
export type OtpVerificationOutputWDto = z.infer<typeof OtpVerificationOutputWSchema>;

export const VerifyAuthTokenDataWSchema = z.object({
  authToken: z.string(),
});
export type VerifyAuthTokenDataWDto = z.infer<typeof VerifyAuthTokenDataWSchema>;

export const VerifyAuthTokenResponseWSchema = z.object({
  token: z.string(),
});
export type VerifyAuthTokenResponseWDto = z.infer<typeof VerifyAuthTokenResponseWSchema>;

export const PatchUserWSchema = z
  .object({
    lang: LanguageSchema,
    membershipPass: z.object({
      memberHandle: z.string().optional(),
    }),
    notificationsEnabled: z.boolean().optional(),
  })
  .deepPartial();
export type PatchUserWDto = z.infer<typeof PatchUserWSchema>;
