import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortPerkRestoredPropertiesSchema = z.object({
  perkAccessId: z.string().uuid(),
  perkId: z.string().uuid(),
});
export type CohortPerkRestoredProperties = z.infer<typeof CohortPerkRestoredPropertiesSchema>;

export type CohortPerkRestoredEventStruct = UserEventStruct<
  'cohort.perk.restored',
  keyof CohortPerkRestoredProperties,
  CohortPerkRestoredProperties,
  CohortPerkRestoredProperties
>;

export const CohortPerkRestoredEventSpec: UserEventSpec<CohortPerkRestoredEventStruct> = {
  id: 'cohort.perk.restored',
  description: 'Event Triggerred when a user PerkAccess is restored',
  propertiesSchema: CohortPerkRestoredPropertiesSchema,
  exposedPropertiesSchema: CohortPerkRestoredPropertiesSchema,
  resources: {
    perkId: 'cohort.perk',
  },
  rulesEngineConfig: {
    isVisible: false,
    exposedFields: [],
  },
};
