import type {PerkIntegrationSpec, PerkIntegrationStruct} from '@cohort/shared/apps/app';
import {z} from 'zod';

export const CohortQrCodeConfigSchema = z.object({});
export type CohortQrCodeConfig = z.infer<typeof CohortQrCodeConfigSchema>;

export const CohortQrCodeStateSchema = z.object({});
export type CohortQrCodeState = z.infer<typeof CohortQrCodeStateSchema>;

export const CohortQrCodeAccessDataSchema = z.object({});
export type CohortQrCodeAccessData = z.infer<typeof CohortQrCodeAccessDataSchema>;

export const CohortQrCodeUsageDataSchema = z.object({
  qrCodeTokenId: z.string(),
  qrReaderId: z.string(),
  qrReaderName: z.string(),
});
export type CohortQrCodeUsageData = z.infer<typeof CohortQrCodeUsageDataSchema>;

export const ExposedCohortQrCodeUsageDataSchema = z.object({
  qrReaderId: z.string(),
  qrReaderName: z.string(),
});
export type ExposedCohortQrCodeUsageData = z.infer<typeof ExposedCohortQrCodeUsageDataSchema>;

export type CohortQrCodePerkIntegrationStruct = PerkIntegrationStruct<
  'cohort.qr-code',
  CohortQrCodeConfig,
  CohortQrCodeState,
  CohortQrCodeAccessData,
  null,
  CohortQrCodeUsageData,
  ExposedCohortQrCodeUsageData
>;
export const CohortQrCodePerkIntegrationSpec: PerkIntegrationSpec<CohortQrCodePerkIntegrationStruct> =
  {
    id: 'cohort.qr-code' as const,
    configSchema: CohortQrCodeConfigSchema,
    stateSchema: CohortQrCodeStateSchema,
    accessDataSchema: CohortQrCodeAccessDataSchema,
    usageInputDataSchema: z.null(),
    internalUsageDataSchema: CohortQrCodeUsageDataSchema,
    exposedUsageDataSchema: ExposedCohortQrCodeUsageDataSchema,
    defaultMaxUsagesPerToken: null,
    requiresMerchantConnection: false,
  };
