import type {CampaignJourneyWDto} from '@cohort/wallet-schemas/campaign';
import type {OwnershipWDto} from '@cohort/wallet-schemas/ownership';
import type {PerkSpaceViewWDto} from '@cohort/wallet-schemas/perkAccess';
import {createContext} from 'react';

export type JourneyContextType = {
  campaign: CampaignJourneyWDto;
  ownerships: OwnershipWDto[];
  perks: PerkSpaceViewWDto[];
};

const JourneyContext = createContext<JourneyContextType | undefined>(undefined);

export default JourneyContext;
