import NewVersionToast from '@cohort/wallet/components/toasts/NewVersionToast';
import {Colors} from '@cohort/wallet/lib/Theme';
import {CheckCircle, WarningCircle} from '@phosphor-icons/react';
import {X} from '@phosphor-icons/react';
import type {Id, ToastOptions, TypeOptions} from 'react-toastify';
import {toast} from 'react-toastify';

export function getIcon(type: TypeOptions): JSX.Element | null {
  switch (type) {
    case 'success':
      return <CheckCircle className=" text-green-400" />;
    case 'error':
      return <WarningCircle className=" text-red-400" />;
    case 'warning':
      return <WarningCircle className="text-yellow-400" />;
    default:
      return null;
  }
}

export function getConfig(hasDarkBg: boolean): Partial<ToastOptions> {
  return {
    style: {
      backgroundColor: hasDarkBg ? Colors.DarkGray : Colors.White,
    },
    bodyClassName: '!py-6 md:!py-0',
    bodyStyle: {
      color: hasDarkBg ? Colors.White : Colors.Black,
    },
    closeButton: ({closeToast}) => (
      <div onClick={closeToast}>
        <X
          style={{
            color: hasDarkBg ? Colors.White : Colors.Black,
            minWidth: '1.5rem',
            maxWidth: '1.5rem',
            minHeight: '1.5rem',
            maxHeight: '1.5rem',
          }}
        />
      </div>
    ),
  };
}

export function notifyNewVersionAvailable(hasDarkBg: boolean): Id {
  return toast(<NewVersionToast />, {
    autoClose: false,
    closeOnClick: false,
    draggable: false,
    ...getConfig(hasDarkBg),
  });
}

export default function notify(type: TypeOptions, message: string, options?: ToastOptions): Id {
  return toast(message, {
    toastId: 'toast',
    ...options,
    type,
    icon: getIcon(type),
    ...getConfig(true),
  });
}
