export const MAX_CAMPAIGN_SIZE = 10_000_000;
export const MAX_ORDERS_REQUESTS = 1_000;
export const MAX_NFTS_PER_BATCH_MINT = 650;
export const USER_AUTH_TOKEN_EXPIRATION = 60 * 10; // 10 minutes
export const QR_CODE_EXPIRATION_SECONDS = 60 * 10; // 10 minutes
export const REDEEM_CODES_MAX_QUANTITY = 50_000;

export const REDACTED_FIELD = '******';

export const ADMIN_ORGANIZATION_HEADER = 'X-Organization';
export const ADMIN_DEFAULT_SIGNIN_SUBDOMAIN = 'signin';
export const ADMIN_OAUTH_REDIRECT_SUBDOMAIN = 'redirect';

export const SSO_GOOGLE_DEFAULT_PROVIDER = {
  name: 'Google',
  logoFileKey: 'assets/google-logo.png',
  type: 'oidc',
  providerId: 'oidc.google',
} as const;

/**
 * Reference IDs to exclude from the user properties list.
 * We do not want to overcharge the user analytics table with theses properties since they
 * can be found elsewhere in the page.
 */
export const USER_PROPERTIES_REF_IDS_TO_HIDE = [
  'cohort.email',
  'cohort.engagement-score',
  'cohort.member-since',
  'cohort.last-active',
  'cohort.cohorts',
  'cohort.cohorts-count',
  'cohort.digital-assets',
  'cohort.digital-assets-count',
  'cohort.journeys',
  'cohort.journeys-count',
  'cohort.perks',
  'cohort.perks-count',
  'cohort.used-perks',
  'cohort.used-perks-count',
];
