import type {TikTokAppStruct} from '@cohort/shared/apps/tiktok';
import {TIKTOK_APP_ID, TikTokAppSpec} from '@cohort/shared/apps/tiktok';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {App} from '@cohort/wallet/apps';
import {createConnectAccountTriggerIntegration} from '@cohort/wallet/apps/common/triggers/ConnectAccountTriggerIntegration';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

const TikTokApp: App<TikTokAppStruct> = {
  spec: TikTokAppSpec,
  perkIntegrations: [],
  triggerIntegrations: [
    createConnectAccountTriggerIntegration(TIKTOK_APP_ID),
  ] as TriggerIntegration[],
  logo: <SvgAppIcon name="tiktok" height={40} width={40} />,
};

export default TikTokApp;
