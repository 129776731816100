import {Navigate} from 'react-router-dom';

const StoreController = {
  protectedRoutes: [
    {
      path: 'checkout',
      lazy: () => import('@cohort/wallet/pages/store/CheckoutPage'),
    },
    {
      path: 'orders/:orderId',
      lazy: () => import('@cohort/wallet/pages/store/OrderPage'),
    },
  ],
  publicRoutes: [
    {
      path: 'login',
      lazy: () => import('@cohort/wallet/pages/store/StoreLoginPage'),
    },
  ],
  bothPublicAndProtectedRoutes: [
    {
      path: '*',
      element: <Navigate to="/store/not-found" replace />,
    },
  ],
};

export default StoreController;
