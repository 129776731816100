import {DateSchema} from '@cohort/shared/schema/common';
import type {
  ResourceListDataType,
  UserPropertyDataType,
  UserPropertyValue,
} from '@cohort/shared/schema/common/userProperty';
import {UserPropertyDataTypeSchema} from '@cohort/shared/schema/common/userProperty';
import {tryParse} from '@cohort/shared/typeUtils';
import {match} from 'ts-pattern';
import type {SafeParseReturnType} from 'zod';
import {z} from 'zod';

export function getParser(dataType: UserPropertyDataType): z.ZodSchema {
  const parser = match(dataType)
    .with('boolean', () => z.boolean())
    .with('date', () => DateSchema)
    .with('number', () => z.number())
    .with('string', () => z.string())
    .with('string_list', () => z.array(z.string()))
    .with('resource_list', () => z.array(z.object({id: z.string(), name: z.string()})))
    .exhaustive();
  return parser;
}

export function parseValue(dataType: UserPropertyDataType, value: unknown): UserPropertyValue {
  return getParser(dataType).parse(value);
}

export function safeParseValue(
  dataType: UserPropertyDataType,
  value: unknown
): SafeParseReturnType<unknown, UserPropertyValue> {
  return getParser(dataType).safeParse(value);
}

// convert raw strings to real types so they can be processed by the above parsers
export function tryParseFromCsv(
  value: string | undefined
): number | string | Array<string> | ResourceListDataType | undefined {
  if (value === undefined) {
    return undefined;
  }
  // parse string_list, resource_list and convert numbers
  const parsedValue = tryParse(value, v => JSON.parse(v as string));
  if (parsedValue !== undefined) {
    return parsedValue;
  }
  // return string value
  return value;
}

export function getValidUserPropertyDataTypes(values: Array<unknown>): Array<UserPropertyDataType> {
  const result: Array<UserPropertyDataType> = [];
  const dataTypes = Object.keys(UserPropertyDataTypeSchema.Values) as Array<UserPropertyDataType>;

  for (const dataType of dataTypes) {
    const parser = getParser(dataType);
    if (values.some(value => parser.safeParse(value).success)) {
      result.push(dataType);
    }
  }

  // any value can be converted to a string
  if (!result.includes('string')) {
    result.push('string');
  }

  return result;
}
