import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';

export type CohortJourneysCountUserPropertyStruct = UserPropertyStruct<
  'cohort.journeys-count',
  number
>;

export const CohortJourneysCountUserPropertySpec: UserPropertySpec<CohortJourneysCountUserPropertyStruct> =
  {
    id: 'cohort.journeys-count',
    name: 'Number of Journeys',
    dataType: 'number',
    scope: 'global',
    defaultValue: 0,
  };
