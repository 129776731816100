import type {CohortVisitLinkTriggerStruct} from '@cohort/shared/apps/cohort/triggers/visitLink';
import type {ActionCtaComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import PrimaryButton from '@cohort/wallet/components/button/PrimaryButton';
import {useTranslation} from 'react-i18next';

const CohortVisitLinkTriggerIntegrationActionCtaComponent: React.FC<
  ActionCtaComponentProps<CohortVisitLinkTriggerStruct>
> = ({config, processStepVerification, trackingData}) => {
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'triggerIntegrations.visit-link',
  });

  return (
    <PrimaryButton
      size="small"
      className="w-full md:w-auto"
      type="button"
      text={t('actionButton')}
      tracking={{
        namespace: 'journeys.detail.actionCta',
        metadata: trackingData,
      }}
      onClick={() => {
        window.open(config.url, '_blank');
        processStepVerification();
      }}
    />
  );
};

export default CohortVisitLinkTriggerIntegrationActionCtaComponent;
