import type {AppSpec, AppStruct} from '@cohort/shared/apps/app';
import type {TikTokConnectorStruct} from '@cohort/shared/apps/tiktok/userConnector';
import {TikTokConnectorSpec} from '@cohort/shared/apps/tiktok/userConnector';
import type {TriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import {createConnectAccountTriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';
import {
  createAppUsernameUserPropertySpec,
  createBaseAppUserPropertySpec,
} from '@cohort/shared/apps/userProperty';

export const TIKTOK_APP_ID = 'tiktok' as const;
export const TIKTOK_APP_NAME = 'TikTok' as const;

export const TikTokUserPropertiesSpecs = [
  ...createBaseAppUserPropertySpec(TIKTOK_APP_ID, TIKTOK_APP_NAME),
  createAppUsernameUserPropertySpec(TIKTOK_APP_ID, TIKTOK_APP_NAME),
  {
    id: 'tiktok.followers-count',
    dataType: 'number',
    name: 'TikTok Number of Followers',
    scope: 'profile',
    profileKey: 'followersCount',
  },
  {
    id: 'tiktok.following-count',
    dataType: 'number',
    name: 'TikTok Number of Following',
    scope: 'profile',
    profileKey: 'followingCount',
  },
  {
    id: 'tiktok.likes-count',
    dataType: 'number',
    name: 'TikTok Number of Likes',
    scope: 'profile',
    profileKey: 'likesCount',
  },
  {
    id: 'tiktok.videos-count',
    dataType: 'number',
    name: 'TikTok Number of Videos',
    scope: 'profile',
    profileKey: 'videosCount',
  },
] as const satisfies ReadonlyArray<UserPropertySpec<UserPropertyStruct>>;
type TikTokUserPropertyIds = (typeof TikTokUserPropertiesSpecs)[number]['id'];

export const TikTokTriggerIntegrationSpecs = [
  createConnectAccountTriggerIntegrationSpec(TIKTOK_APP_ID),
] as const satisfies ReadonlyArray<TriggerIntegrationSpec>;
type TikTokTriggerIntegrationIds = (typeof TikTokTriggerIntegrationSpecs)[number]['id'];

export type TikTokAppStruct = AppStruct<
  'tiktok',
  null,
  TikTokConnectorStruct,
  null,
  never,
  never,
  never,
  TikTokTriggerIntegrationIds,
  never,
  TikTokUserPropertyIds,
  never,
  never
>;

export const TikTokAppSpec: AppSpec<TikTokAppStruct> = {
  id: TIKTOK_APP_ID,
  name: TIKTOK_APP_NAME,
  merchantConnector: null,
  userConnector: TikTokConnectorSpec,
  syncSpec: null,
  actionSpecs: [],
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: [],
  triggerIntegrationSpecs: TikTokTriggerIntegrationSpecs,
  userEventSpecs: [],
  userPropertySpecs: TikTokUserPropertiesSpecs,
  resourceSpecs: [],
  mediaSpecs: [],
};
