import {z} from 'zod';

export const CustomizableTermsSchema = z.enum([
  'badges',
  'digitalAssets',
  'experienceSpace',
  'home',
  'journeys',
  'perks',
  'rewards',
]);
export type CustomizableTerms = z.infer<typeof CustomizableTermsSchema>;

export const CustomTermsRecordSchema = z.record(CustomizableTermsSchema, z.string());
export type CustomTermsRecord = z.infer<typeof CustomTermsRecordSchema>;
