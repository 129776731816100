import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortBadgeGainedPropertiesSchema = z.object({
  badgeId: z.string(),
  cohortId: z.string().uuid(),
});
export type CohortBadgeGainedProperties = z.infer<typeof CohortBadgeGainedPropertiesSchema>;

export type CohortBadgeGainedEventStruct = UserEventStruct<
  'cohort.badge.gained',
  keyof CohortBadgeGainedProperties,
  CohortBadgeGainedProperties,
  CohortBadgeGainedProperties
>;

export const CohortBadgeGainedEventSpec: UserEventSpec<CohortBadgeGainedEventStruct> = {
  id: 'cohort.badge.gained',
  propertiesSchema: CohortBadgeGainedPropertiesSchema,
  exposedPropertiesSchema: CohortBadgeGainedPropertiesSchema,
  description: 'Event Triggerred when a user gains a badge',
  resources: {
    badgeId: 'cohort.badge',
    cohortId: 'cohort.cohort',
  },
  rulesEngineConfig: {
    isVisible: true,
    exposedFields: ['badgeId'],
  },
};
