const JourneyController = {
  protectedRoutes: [
    {
      path: 'steps/:stepId/triggers/:triggerId/use',
      lazy: () => import('@cohort/wallet/pages/journeys/TriggerUsagePage'),
    },
    {
      path: 'rewards/*',
      children: [
        {
          path: 'perks/:perkId',
          children: [
            {
              index: true,
              lazy: () => import('@cohort/wallet/pages/journeys/PerkDetailPage'),
            },
            {
              path: 'perk-accesses/:perkAccessId/use',
              lazy: () => import('@cohort/wallet/pages/journeys/PerkUsagePage'),
            },
          ],
        },
        {
          path: 'ownerships',
          children: [
            {
              path: 'imports/new',
              lazy: () => import('@cohort/wallet/pages/journeys/DigitalAssetImportPage'),
            },
            {
              path: ':ownershipId',
              children: [
                {
                  index: true,
                  lazy: () => import('@cohort/wallet/pages/journeys/DigitalAssetDetailPage'),
                },
                {
                  path: 'transfer/new',
                  lazy: () => import('@cohort/wallet/pages/journeys/NewTransferPage'),
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  publicRoutes: [
    {
      path: 'login',
      lazy: () => import('@cohort/wallet/pages/journeys/JourneyLoginPage'),
    },
  ],
};

export default JourneyController;
