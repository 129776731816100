import StoreLogo from '@cohort/components-xps/components/navbar/StoreLogo';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
} from '@cohort/shared-frontend/components/Accordion';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import useClickTracker from '@cohort/wallet/hooks/clickTracker';
import useRootName from '@cohort/wallet/hooks/rootName';
import {useAppStore} from '@cohort/wallet/hooks/stores/app';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {getRootPaths} from '@cohort/wallet/lib/Pages';
import {CaretLeft, List} from '@phosphor-icons/react';
import {AccordionTrigger} from '@radix-ui/react-accordion';
import React from 'react';
import {useLocation} from 'react-router-dom';

type NavBarProps = {
  campaignSlug?: string;
  journeyId?: string;
  handleBackButtonClick?: () => void;
  logoFileKey: string | null;
  appTitle: string;
  children?: React.ReactNode;
};

const Navbar: React.FC<NavBarProps> = ({
  campaignSlug,
  journeyId: campaignId,
  handleBackButtonClick,
  logoFileKey,
  appTitle,
  children,
}) => {
  const {isMobile} = useScreenSize();
  const {accentColor} = useThemeContext();
  const location = useLocation();
  const navigationType = useAppStore(store => store.navigationType);
  const trackingCallback = useClickTracker('navbar.back');
  const appName = useRootName();

  function isRootPath(): boolean {
    return getRootPaths(campaignSlug, campaignId).includes(location.pathname);
  }

  return (
    <Accordion type="single" collapsible className="z-40 w-full border-b">
      <AccordionItem value="navbar" className="m-auto max-w-[var(--content-max-width)] border-0">
        <div
          className={cn(
            'grid h-[var(--navbar-desktop-height)]',
            isMobile && 'grid-cols-1 grid-rows-1'
          )}
          style={{
            gridTemplateColumns: !isMobile
              ? `${`var(--xps-${appName}-left-panel-width)`} 1fr`
              : undefined,
          }}
        >
          <div className="flex items-center justify-between py-2">
            <button
              data-testid="navbar-back-button"
              className={cn(
                'flex w-full transform items-center px-4 transition duration-1000 md:px-8',
                location.pathname.startsWith('/space') && 'md:border-r'
              )}
              disabled={isRootPath()}
              onClick={() => {
                trackingCallback();
                handleBackButtonClick?.();
              }}
            >
              {!isRootPath() && <CaretLeft className="mr-2 h-5 w-5" aria-hidden="true" />}
              <div className="flex items-center">
                <StoreLogo logoImageFileKey={logoFileKey} name={appTitle} />
                <h3 className="mr-1.5 line-clamp-2 text-left" title={appTitle}>
                  {appTitle}
                </h3>
              </div>
            </button>
            {navigationType === 'burger' && (
              <AccordionTrigger className="block pr-4 md:hidden">
                <List color={accentColor} size={24} />
              </AccordionTrigger>
            )}
          </div>

          {!isMobile && children}
        </div>
        <AccordionContent className="border-t py-4 pl-4">{isMobile && children}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default Navbar;
