import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortNewJourneyStepAvailablePropertiesSchema = z.object({
  campaignId: z.string().uuid(),
  journeyParticipationId: z.string().uuid(),
  journeyStepId: z.string().uuid(),
});
export type CohortNewJourneyStepAvailableProperties = z.infer<
  typeof CohortNewJourneyStepAvailablePropertiesSchema
>;

export type CohortNewJourneyStepAvailableEventStruct = UserEventStruct<
  'cohort.journey.new-step-available',
  keyof CohortNewJourneyStepAvailableProperties,
  CohortNewJourneyStepAvailableProperties,
  CohortNewJourneyStepAvailableProperties
>;

export const CohortNewJourneyStepAvailableEventSpec: UserEventSpec<CohortNewJourneyStepAvailableEventStruct> =
  {
    id: 'cohort.journey.new-step-available',
    description: 'Event Triggerred when a new step is available to a user in a Journey',
    propertiesSchema: CohortNewJourneyStepAvailablePropertiesSchema,
    exposedPropertiesSchema: CohortNewJourneyStepAvailablePropertiesSchema,
    resources: {
      campaignId: 'cohort.journey',
    },
    rulesEngineConfig: {
      isVisible: false,
      exposedFields: [],
    },
  };
export default CohortNewJourneyStepAvailableEventSpec;
