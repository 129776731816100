import {z} from 'zod';

export const CohortImageMediaConfigSchema = z.object({
  fileKey: z.string(),
});
export type CohortImageMediaConfig = z.infer<typeof CohortImageMediaConfigSchema>;

export type CohortImageMediaStruct = {
  Kind: 'cohort.image';
  Config: CohortImageMediaConfig;
};

export const CohortImageMediaSpec = {
  kind: 'cohort.image',
  configSchema: CohortImageMediaConfigSchema,
};
