import {GarminConnectActivitySchema} from '@cohort/shared/apps/garmin-connect/activity';
import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import type {z} from 'zod';

export const GarminConnectActivityCreatedPropertiesSchema = GarminConnectActivitySchema;
export type GarminConnectActivityCreatedProperties = z.infer<
  typeof GarminConnectActivityCreatedPropertiesSchema
>;

export type GarminConnectActivityCreatedEventStruct = UserEventStruct<
  'garmin-connect.activity.created',
  keyof GarminConnectActivityCreatedProperties,
  GarminConnectActivityCreatedProperties,
  GarminConnectActivityCreatedProperties
>;

export const GarminConnectActivityCreatedEventSpec: UserEventSpec<GarminConnectActivityCreatedEventStruct> =
  {
    id: 'garmin-connect.activity.created',
    description: 'Event Triggerred when a user creates an activity on Garmin Connect',
    propertiesSchema: GarminConnectActivityCreatedPropertiesSchema,
    exposedPropertiesSchema: GarminConnectActivityCreatedPropertiesSchema,
    resources: {},
    rulesEngineConfig: {
      isVisible: false,
      exposedFields: [],
    },
  };
