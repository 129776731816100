import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';
import type {ResourceListDataType} from '@cohort/shared/schema/common/userProperty';

export type CohortJourneysUserPropertyStruct = UserPropertyStruct<
  'cohort.journeys',
  ResourceListDataType
>;

export const CohortJourneysUserPropertySpec: UserPropertySpec<CohortJourneysUserPropertyStruct> = {
  id: 'cohort.journeys',
  name: 'Journeys',
  dataType: 'resource_list',
  scope: 'global',
  resource: 'cohort.journey',
  defaultValue: [],
};
