import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortAppDisconnectedPropertiesSchema = z.object({
  appId: z.string(),
  connectionId: z.string().uuid(),
});
export type CohortAppDisconnectedProperties = z.infer<typeof CohortAppDisconnectedPropertiesSchema>;

export type CohortAppDisconnectedEventStruct = UserEventStruct<
  'cohort.app.disconnected',
  keyof CohortAppDisconnectedProperties,
  CohortAppDisconnectedProperties,
  CohortAppDisconnectedProperties
>;

export const CohortAppDisconnectedEventSpec: UserEventSpec<CohortAppDisconnectedEventStruct> = {
  id: 'cohort.app.disconnected',
  description: 'Event Triggerred when a user disconnects an app in their Experience Space',
  propertiesSchema: CohortAppDisconnectedPropertiesSchema,
  exposedPropertiesSchema: CohortAppDisconnectedPropertiesSchema,
  resources: {
    appId: 'cohort.app',
  },
  rulesEngineConfig: {
    isVisible: true,
    exposedFields: ['appId'],
  },
};
