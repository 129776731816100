import Title from '@cohort/components-xps/components/Title';
import type {CreateQrCodeActionStruct} from '@cohort/shared/apps/cohort/actions/createQrCode';
import {defaultErrorMessage} from '@cohort/shared/models';
import {getTextColor} from '@cohort/shared/schema/common';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import type {PerkUsageComponentProps} from '@cohort/wallet/apps/PerkIntegration';
import PrimaryButton from '@cohort/wallet/components/button/PrimaryButton';
import SecondaryButton from '@cohort/wallet/components/button/SecondaryButton';
import Subtitle from '@cohort/wallet/components/Subtitle';
import useNotify from '@cohort/wallet/hooks/notify';
import {useApi} from '@cohort/wallet/hooks/useApi';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {AppsApi} from '@cohort/wallet/lib/Endpoints';
import {formatDuration} from '@cohort/wallet/lib/Utils';
import {QRCodeCanvas} from 'qrcode.react';
import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

export const CohortQrCodePerkUsageComponent: React.FC<PerkUsageComponentProps> = ({
  perkAccess,
  perk,
  onClose,
}) => {
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'perkIntegrations.qr-code.perkUsageComponent',
  });
  const executeAction = useApi(AppsApi.executeAction<CreateQrCodeActionStruct>);
  const [token, setToken] = useState<string>();
  const [expiration, setExpiration] = useState<number>();
  const [countdown, setCountdown] = useState<number>();
  const {hasDarkBg, backgroundColor} = useThemeContext();
  const notify = useNotify();

  const intervalRef = useRef<NodeJS.Timeout>();

  const refreshQrCode = useCallback(() => {
    executeAction({
      appId: 'cohort',
      merchantConnectionId: null,
      userConnectionId: null,
      actionId: 'create-qr-code',
      input: {perkAccessId: perkAccess.id},
    })
      .then(res => {
        setToken(res.output.qrCode);
        setExpiration(res.output.expiration);
      })
      .catch(err => {
        if (isCohortError(err, 'perk.usages-limit-reached')) {
          notify('error', t('maxUsagesReached'), {autoClose: false});
          return;
        }
        notify('error', defaultErrorMessage);
      });
  }, [executeAction, perkAccess, notify, t]);

  const onRefreshQrCode = useCallback(() => {
    refreshQrCode();
  }, [refreshQrCode]);

  useEffect(() => {
    refreshQrCode();
  }, [refreshQrCode]);

  useEffect(() => {
    if (expiration === undefined) {
      return;
    }
    const interval = setInterval(() => {
      const now = Math.floor(Date.now() / 1000);
      setCountdown(expiration - now);
    }, 1000);
    intervalRef.current = interval;
    return () => clearInterval(interval);
  }, [expiration]);

  useEffect(() => {
    if (countdown !== undefined && countdown <= 0 && intervalRef.current !== undefined) {
      clearInterval(intervalRef.current);
    }
  }, [countdown]);

  if (token === undefined) {
    return <Fragment />;
  }

  const textColor = getTextColor(backgroundColor);
  const qrCodeFg = hasDarkBg ? backgroundColor : textColor;
  const qrCodeBg = hasDarkBg ? textColor : backgroundColor;

  return (
    <Fragment>
      <div className="space-y-2 pb-7">
        <Title content={perk.displayName} className="text-center md:text-left" />
        {perk.description !== null && (
          <Subtitle
            content={
              <div
                dangerouslySetInnerHTML={{
                  __html: perk.description,
                }}
              ></div>
            }
            className="text-center md:text-left"
          />
        )}
      </div>

      <div className="w-full text-center md:text-left">
        <QRCodeCanvas
          fgColor={qrCodeFg}
          bgColor={qrCodeBg}
          className="m-auto rounded-lg border-2 p-2 md:m-0"
          style={{
            borderColor: textColor,
            backgroundColor: qrCodeBg,
          }}
          value={token}
          size={300}
        />
        <div className="mt-4 text-sm">
          {countdown !== undefined && countdown > 0 && (
            <p className={textColor}>
              {t('labelCountdown', {countdown: formatDuration(countdown)})}
            </p>
          )}
          {countdown !== undefined && countdown <= 0 && (
            <p className="text-red-700">{t('labelExpired')}</p>
          )}
        </div>
        <div className="mt-4 flex flex-col gap-x-4 md:flex-row">
          <PrimaryButton
            onClick={onRefreshQrCode}
            text={t('buttonRefresh')}
            className="outline-0 focus:ring-0 focus:ring-offset-0"
            tracking={{
              namespace: 'perks.usage',
              metadata: {
                perkIntegrationId: perk.integration?.perkIntegrationId,
                action: 'refreshCode',
                perkAccessId: perkAccess.id,
                perkId: perk.id,
              },
            }}
          />

          <SecondaryButton
            onClick={onClose}
            text={t('buttonClose')}
            className="mt-3 outline-0 focus:ring-0 focus:ring-offset-0 md:mt-0"
            tracking={{
              namespace: 'perks.usage',
              metadata: {
                perkIntegrationId: perk.integration?.perkIntegrationId,
                action: 'close',
                perkAccess: perkAccess.id,
                perkId: perk.id,
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};
