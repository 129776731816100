// i18nOwl-ignore [errorAccessDenied.title, errorAccessDenied.description, errorDefault.title, errorDefault.description, errorInsufficientPermissions.title, errorInsufficientPermissions.description]
import Title from '@cohort/components-xps/components/Title';
import SecondaryButton from '@cohort/wallet/components/button/SecondaryButton';
import BaseModal from '@cohort/wallet/components/modals/BaseModal';
import DrawerModal from '@cohort/wallet/components/modals/DrawerModal';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';
import {useTranslation} from 'react-i18next';

type OAuthErrorModalProps = {
  title?: string;
  description?: string;
  onClose: () => void;
};

const OAuthErrorModal: React.FC<OAuthErrorModalProps> = ({title, description, onClose}) => {
  const {isMobile} = useScreenSize();
  const Modal = isMobile ? DrawerModal : BaseModal;
  const {t} = useTranslation('components', {keyPrefix: 'modals.oauth.oauthErrorModal'});

  return (
    <Modal
      title={<Title content={title ?? t('errorDefault.title')} />}
      tracking={{namespace: 'modals.apps.oauthError'}}
      onClose={onClose}
    >
      <p className="text-sm">{description ?? t('errorDefault.description')}</p>
      <SecondaryButton
        className="mt-4 w-full"
        text={t('closeModal')}
        onClick={onClose}
        tracking={{namespace: 'modals.apps.oauthError.close'}}
      />
    </Modal>
  );
};

export default OAuthErrorModal;
