import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {MetadataFormattedSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const CohortPerkUsedPropertiesSchema = z.object({
  perkId: z.string().uuid(),
  perkAccessId: z.string().uuid(),
  perkIntegrationId: z.string().nullable(), // Can't have PerkIntegrationIdSchema because of circular dependencies
  perkUsageData: z.unknown(),
  perkMetadata: MetadataFormattedSchema,
});
export type CohortPerkUsedProperties = z.infer<typeof CohortPerkUsedPropertiesSchema>;

export type CohortPerkUsedEventStruct = UserEventStruct<
  'cohort.perk.used',
  keyof CohortPerkUsedProperties,
  CohortPerkUsedProperties,
  CohortPerkUsedProperties
>;

export const CohortPerkUsedEventSpec: UserEventSpec<CohortPerkUsedEventStruct> = {
  id: 'cohort.perk.used',
  description: 'Event Triggerred when a user uses a Perk',
  propertiesSchema: CohortPerkUsedPropertiesSchema,
  exposedPropertiesSchema: CohortPerkUsedPropertiesSchema,
  resources: {
    perkId: 'cohort.perk',
  },
  rulesEngineConfig: {
    isVisible: true,
    exposedFields: ['perkId'],
  },
};
