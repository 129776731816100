import {useMatch} from 'react-router-dom';

export type RootName = 'store' | 'journey' | 'space';

export default function useRootName(): RootName {
  const isStore = useMatch('/store/*');
  const isJourney = useMatch('/journeys/*');
  const isSpace = useMatch('/space/*');

  if (!isStore && !isJourney && !isSpace) {
    throw new Error('Root name not found');
  }
  return isStore ? ('store' as const) : isJourney ? ('journey' as const) : ('space' as const);
}
