import dayjs from 'dayjs';
import {z} from 'zod';

export function startDateBeforeEndDateRefinement({
  startDatePropertyName = 'startDateTimestamp',
  endDatePropertyName = 'endDateTimestamp',
  message = 'errorEndDateBeforeStartDate',
}: {
  startDatePropertyName?: string;
  endDatePropertyName?: string;
  message?: string;
}) {
  return (data: Record<string, unknown>, ctx: z.RefinementCtx) => {
    const startDate = data[startDatePropertyName] as Date | number | string | undefined;
    const endDate = data[endDatePropertyName] as Date | number | string | undefined;

    if (startDate && endDate && dayjs(startDate).isAfter(endDate)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: message,
        path: [endDatePropertyName],
      });
    }
  };
}
