import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortJourneyOptedInPropertiesSchema = z.object({
  campaignId: z.string().uuid(),
  journeyParticipationId: z.string().uuid(),
});
export type CohortJourneyOptedInProperties = z.infer<typeof CohortJourneyOptedInPropertiesSchema>;

export type CohortJourneyOptedInEventStruct = UserEventStruct<
  'cohort.journey.opted-in',
  keyof CohortJourneyOptedInProperties,
  CohortJourneyOptedInProperties,
  CohortJourneyOptedInProperties
>;

export const CohortJourneyOptedInEventSpec: UserEventSpec<CohortJourneyOptedInEventStruct> = {
  id: 'cohort.journey.opted-in',
  description: 'Event Triggerred when a user opts-in a Journey',
  propertiesSchema: CohortJourneyOptedInPropertiesSchema,
  exposedPropertiesSchema: CohortJourneyOptedInPropertiesSchema,
  resources: {
    campaignId: 'cohort.journey',
  },
  rulesEngineConfig: {
    isVisible: true,
    exposedFields: ['campaignId'],
  },
};
