import {DropdownNavigation} from '@cohort/wallet/components/navigation/DropdownNavigation';
import Navbar from '@cohort/wallet/components/navigation/NavBar';
import useHandleLoginNavigation from '@cohort/wallet/hooks/handleLoginNavigation';
import {useAppStore} from '@cohort/wallet/hooks/stores/app';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import {useNavigateWithTransition} from '@cohort/wallet/hooks/useNavigateWithTransition';
import useStoreContext from '@cohort/wallet/hooks/useStoreContext';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import BaseLayout from '@cohort/wallet/layouts/BaseLayout';
import {getStoreLoginRoute} from '@cohort/wallet/lib/Pages';
import {setAmplitudeMetadata} from '@cohort/wallet/lib/Tracking';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

const StoreLayout = (): JSX.Element => {
  const {t} = useTranslation('layouts', {
    keyPrefix: 'store.storeLayout',
  });
  const {campaign, buyingStep} = useStoreContext();
  const merchant = useMerchantContext();
  const {setCampaignTheme} = useThemeContext();
  const navigateWithTransition = useNavigateWithTransition();
  const {withNavbar, disableLogout} = useAppStore(store => ({
    disableLogout: store.disableLogout,
    embedded: store.embedded,
    withNavbar: store.withNavbar,
  }));
  const navigateToLogin = useHandleLoginNavigation();

  useEffect(() => {
    setCampaignTheme(campaign);
  }, [campaign, setCampaignTheme]);

  useEffect(() => {
    setAmplitudeMetadata({merchant, campaign, rootPath: 'store'});
  }, [campaign, merchant]);

  return (
    <BaseLayout
      navbar={
        withNavbar ? (
          <Navbar
            campaignSlug={campaign.store.slug}
            handleBackButtonClick={() => {
              navigateWithTransition(-1, undefined, 'backward');
            }}
            logoFileKey={merchant.logoFileKey}
            appTitle={t('experienceStoreTitle', {name: merchant.name})}
          >
            <div className="flex h-full items-center justify-end pr-8">
              <DropdownNavigation
                withBackButton={buyingStep.current !== 0}
                handlelogin={navigateToLogin}
                showExperienceSpace
                showHelp
                showLogout={!disableLogout}
                logOutSuccessRoute={getStoreLoginRoute(campaign.store.slug)}
              />
            </div>
          </Navbar>
        ) : undefined
      }
    />
  );
};

export default StoreLayout;
