import Title from '@cohort/components-xps/components/Title';
import {defaultErrorMessage} from '@cohort/shared/models';
import type {CohortFormAnswer, CohortFormConfig} from '@cohort/shared/schema/common/cohortForm';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import type {PerkUsageComponentProps} from '@cohort/wallet/apps/PerkIntegration';
import CohortFormComponent from '@cohort/wallet/components/forms/CohortForm';
import {useCohortMutation} from '@cohort/wallet/hooks/api/Query';
import {userAttributesKeys} from '@cohort/wallet/hooks/api/UserAttributes';
import useNotify from '@cohort/wallet/hooks/notify';
import {useApi} from '@cohort/wallet/hooks/useApi';
import {PerkAccessesApi} from '@cohort/wallet/lib/Endpoints';
import {useQueryClient} from '@tanstack/react-query';
import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

export const CohortFormPerkUsageComponent: React.FC<PerkUsageComponentProps> = ({
  perkAccess,
  perk,
  onPerkUsageSuccess,
}) => {
  const {t} = useTranslation('app-cohort', {keyPrefix: 'perkIntegrations.form.perkUsageComponent'});
  const doUsePerkAccess = useApi(PerkAccessesApi.doUsePerkAccess);
  const notify = useNotify();
  const queryClient = useQueryClient();

  const perkConfig = perk.integration?.config as CohortFormConfig;

  const {isLoading, mutate: doUsePerkAccessMutation} = useCohortMutation({
    mutationFn: async (values: Record<string, CohortFormAnswer>) =>
      doUsePerkAccess(perkAccess.id, null, {values}),
    onSuccess: () => {
      onPerkUsageSuccess();
      // some perk usages can update user attributes
      queryClient.invalidateQueries(userAttributesKeys.userAttributes);
    },
    onError: err => {
      if (isCohortError(err, 'perk.usages-limit-reached')) {
        notify('error', t('maxUsagesReached'), {autoClose: false});
        return;
      }
      notify('error', defaultErrorMessage);
    },
  });

  return (
    <Fragment>
      <Title
        content={perk.displayName}
        testId="use-perk-form-title"
        className="text-center md:text-left"
      />
      <CohortFormComponent
        config={perkConfig}
        onSubmit={doUsePerkAccessMutation}
        isLoading={isLoading}
        onSubmitTracking={{
          namespace: 'perks.usage',
          metadata: {
            perkIntegrationId: perk.integration?.perkIntegrationId,
            action: 'linkAccount',
            perkAccessId: perkAccess.id,
            perkId: perk.id,
          },
        }}
      />
    </Fragment>
  );
};
