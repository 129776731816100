import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {EmailSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const CohortDigitalAssetTransferredPropertiesSchema = z.object({
  collectionId: z.string().uuid(),
  digitalAssetId: z.string().uuid(),
  ownershipId: z.string().uuid(),
  recipientAddress: z.string(),
  recipientEmail: EmailSchema.optional(),
});
export type CohortDigitalAssetTransferredProperties = z.infer<
  typeof CohortDigitalAssetTransferredPropertiesSchema
>;

export type CohortDigitalAssetTransferredEventStruct = UserEventStruct<
  'cohort.digital-asset.transferred',
  keyof CohortDigitalAssetTransferredProperties,
  CohortDigitalAssetTransferredProperties,
  CohortDigitalAssetTransferredProperties
>;

export const CohortDigitalAssetTransferredEventSpec: UserEventSpec<CohortDigitalAssetTransferredEventStruct> =
  {
    id: 'cohort.digital-asset.transferred',
    description: 'Event Triggerred when a user transfers one of their Digital Assets',
    propertiesSchema: CohortDigitalAssetTransferredPropertiesSchema,
    exposedPropertiesSchema: CohortDigitalAssetTransferredPropertiesSchema,
    resources: {
      collectionId: 'cohort.digital-asset-collection',
    },
    rulesEngineConfig: {
      isVisible: true,
      exposedFields: ['collectionId'],
    },
  };
