import type {SyncIntegrationSpec, SyncIntegrationStruct} from '@cohort/shared/apps/sync';
import {z} from 'zod';

export const ShopifySyncConfigSchema = z.object({});
export type ShopifySyncConfig = z.infer<typeof ShopifySyncConfigSchema>;

export const ShopifySyncStateSchema = z.object({
  lastCustomerId: z.string().nullable(),
});
export type ShopifySyncState = z.infer<typeof ShopifySyncStateSchema>;

export type ShopifySyncStruct = SyncIntegrationStruct<
  ShopifySyncConfig,
  ShopifySyncState,
  never,
  never
>;

export const ShopifySyncIntegrationSpec: SyncIntegrationSpec<ShopifySyncStruct> = {
  userSyncsConfigSchema: ShopifySyncConfigSchema,
  userSyncsStateSchema: ShopifySyncStateSchema,
  contentSyncConfigSchema: null,
  userEventExportConfigSchema: null,
};
