import type {JourneyContextType} from '@cohort/wallet/components/contexts/JourneyContext';
import JourneyContext from '@cohort/wallet/components/contexts/JourneyContext';
import {useContext} from 'react';

const useJourneyContext = (): JourneyContextType => {
  const context = useContext(JourneyContext);
  if (context === undefined) {
    throw new Error('useJourneyContext must be used within a JourneyContextProvider');
  }
  return context;
};

export default useJourneyContext;
