import type {SpaceContextType} from '@cohort/wallet/components/contexts/SpaceContext';
import SpaceContext from '@cohort/wallet/components/contexts/SpaceContext';
import {LoadingScreen} from '@cohort/wallet/components/layout/LoadingScreen';
import {useMerchantSpace} from '@cohort/wallet/hooks/api/Space';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import {getSpaceNotFoundRoute} from '@cohort/wallet/lib/Pages';
import {useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';

const useSpace = (): SpaceContextType | undefined => {
  const user = useUserStore(store => store.user);
  const navigate = useNavigate();
  const {data: space, isError} = useMerchantSpace(user !== null);

  useEffect(() => {
    if (isError) {
      navigate(getSpaceNotFoundRoute());
    }
  }, [isError, navigate]);

  return space;
};

const SpaceContextProvider = (): JSX.Element => {
  const currentSpace = useSpace();

  if (currentSpace === undefined) {
    return <LoadingScreen />;
  }

  return (
    <SpaceContext.Provider value={currentSpace}>
      <Outlet />
    </SpaceContext.Provider>
  );
};

export default SpaceContextProvider;
