import {useAppStore} from '@cohort/wallet/hooks/stores/app';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import sendMessageToParent from '@cohort/wallet/lib/WindowMessaging';
import {useEffect, useRef} from 'react';
import {useLocation, useMatch, useNavigate} from 'react-router-dom';
import {z} from 'zod';
import {shallow} from 'zustand/shallow';

// eslint-disable-next-line react-refresh/only-export-components -- why does he thinks that it's exporting somehing else?
const UpdateLocationMessage = z.object({
  event: z.literal('location.update'),
  payload: z.object({
    pathname: z.string(),
  }),
});

export default function useEmbed(): void {
  const location = useLocation();
  const {isLoggedIn, isAuthenticating, localizationLoaded} = useUserStore(
    store => ({
      isLoggedIn: store.isLoggedIn,
      isAuthenticating: store.isAuthenticating,
      localizationLoaded: store.localizationLoaded,
    }),
    shallow
  );
  const {themeLoaded} = useThemeContext();
  const embedded = useAppStore(store => store.embedded);
  const initialAuthState = useRef(isLoggedIn);
  const navigate = useNavigate();
  const appLoaded = useRef(false);
  const isSpace = useMatch('/space/*');

  useEffect(() => {
    if (!embedded) {
      return;
    }
    sendMessageToParent({
      event: 'location.updated',
      payload: {
        location: window.location.href,
      },
    });
  }, [location, embedded]);

  useEffect(() => {
    if (!embedded) {
      return;
    }

    if (isSpace) {
      if (initialAuthState.current !== isLoggedIn) {
        initialAuthState.current = isLoggedIn;
      }
    }
  }, [isLoggedIn, isSpace, navigate, embedded]);

  useEffect(() => {
    if (!embedded) {
      return;
    }
    const onMessage = (event: MessageEvent): void => {
      const message = UpdateLocationMessage.safeParse(event.data);

      if (message.success) {
        navigate(message.data.payload.pathname);
      }
    };

    window.addEventListener('message', onMessage);
  }, [navigate, embedded]);

  useEffect(() => {
    if (!embedded || !themeLoaded || !localizationLoaded || isAuthenticating || appLoaded.current) {
      return;
    }

    sendMessageToParent({
      event: 'app.loaded',
      payload: {},
    });
    appLoaded.current = true;
  }, [embedded, themeLoaded, localizationLoaded, isAuthenticating]);
}
