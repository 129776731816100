import {DEFAULT_ACCENT_COLOR, DEFAULT_BACKGROUND_COLOR} from '@cohort/shared/schema/common';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {isDark} from '@cohort/shared-frontend/utils/isDark';
import SvgIcon from '@cohort/wallet/components/Svg';
import {Colors} from '@cohort/wallet/lib/Theme';
import type {MerchantWDto} from '@cohort/wallet-schemas/merchant';
import {Fragment} from 'react';

type ErrorPageTemplateProps = {
  code?: number;
  title: string;
  description: string;
  cta?: JSX.Element;
  merchant?: MerchantWDto;
};

const ErrorPageTemplate: React.FC<ErrorPageTemplateProps> = ({
  code,
  title,
  description,
  cta,
  merchant,
}) => {
  const accentColor = merchant ? merchant.accentColorCode ?? DEFAULT_ACCENT_COLOR : undefined;
  const backgroundColor = merchant
    ? merchant.backgroundColorCode ?? DEFAULT_BACKGROUND_COLOR
    : undefined;

  return (
    <div
      className="flex min-h-screen"
      style={{
        backgroundColor,
      }}
    >
      <div className="flex flex-col justify-center px-6 py-12 lg:px-20 xl:px-24">
        <div className="max-w-sm lg:w-96">
          <div className="flex flex-col gap-y-2">
            <p
              style={{
                color: accentColor,
              }}
              className={cn('font-bold capitalize', !accentColor && 'text-[--cohort-color]')}
            >
              {code}
            </p>
            <p
              className="text-2xl font-semibold"
              style={{
                color:
                  !backgroundColor || (backgroundColor && !isDark(backgroundColor))
                    ? Colors.Black
                    : Colors.White,
              }}
            >
              {title}
            </p>
            <p className="mb-4 text-lg text-gray-500">{description}</p>
            {cta}
          </div>
        </div>
      </div>

      <div className="relative hidden flex-1 lg:block">
        <div
          className={cn(
            'flex h-full items-center justify-center',
            backgroundColor ? (isDark(backgroundColor) ? 'bg-white/8' : 'bg-black/4') : undefined
          )}
        >
          {merchant?.logoFileKey ? (
            <img
              className="z-10"
              width={100}
              src={getImageUrl(import.meta.env.COHORT_ENV, merchant.logoFileKey, {
                h: Sizes.M,
                w: Sizes.M,
              })}
            />
          ) : (
            <Fragment>
              <SvgIcon name="CohortErrorBg" className="absolute min-h-full min-w-full" />
              <SvgIcon name="CohortLogo" height={50} className="z-10" />
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorPageTemplate;
