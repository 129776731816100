import type {SyncIntegrationSpec, SyncIntegrationStruct} from '@cohort/shared/apps/sync';
import {z} from 'zod';

export const SpotifyContentSyncConfigSchema = z.object({});
export type SpotifyContentSyncConfig = z.infer<typeof SpotifyContentSyncConfigSchema>;

export type SpotifySyncStruct = SyncIntegrationStruct<
  never,
  never,
  SpotifyContentSyncConfig,
  never
>;

export const SpotifySyncSpec: SyncIntegrationSpec<SpotifySyncStruct> = {
  contentSyncConfigSchema: SpotifyContentSyncConfigSchema,
  userSyncsConfigSchema: null,
  userSyncsStateSchema: null,
  userEventExportConfigSchema: null,
};
