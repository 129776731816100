import type {EventId} from '@cohort/shared/apps';
import type {
  NotificationIntegrationSpec,
  NotificationIntegrationStruct,
} from '@cohort/shared/apps/app';
import {CohortBadgeGainedEventSpec} from '@cohort/shared/apps/cohort/userEvents/badge/badgeGained';
import {CohortBadgeLostEventSpec} from '@cohort/shared/apps/cohort/userEvents/badge/badgeLost';
import {CohortDigitalAssetReceivedEventSpec} from '@cohort/shared/apps/cohort/userEvents/digitalAsset/digitalAssetReceived';
import {CohortDigitalAssetTransferredEventSpec} from '@cohort/shared/apps/cohort/userEvents/digitalAsset/digitalAssetTransferred';
import {CohortJourneyCompletedEventSpec} from '@cohort/shared/apps/cohort/userEvents/journey/journeyCompleted';
import {CohortJourneyStepCompletedEventSpec} from '@cohort/shared/apps/cohort/userEvents/journey/journeyStepCompleted';
import {CohortNewJourneyAvailableEventSpec} from '@cohort/shared/apps/cohort/userEvents/journey/newJourneyAvailable';
import CohortNewJourneyStepAvailableEventSpec from '@cohort/shared/apps/cohort/userEvents/journey/newJourneyStepAvailable';
import {CohortPerkReceivedEventSpec} from '@cohort/shared/apps/cohort/userEvents/perk/perkReceived';
import {CohortUserCreatedEventSpec} from '@cohort/shared/apps/cohort/userEvents/userCreated';
import {z} from 'zod';

export const CohortEmailNotificationConfigSchema = z.object({
  template: z.enum(['default', 'custom']),
});
export type CohortEmailNotificationConfig = z.infer<typeof CohortEmailNotificationConfigSchema>;

export type CohortEmailNotificationIntegrationStruct = NotificationIntegrationStruct<
  'cohort.email',
  CohortEmailNotificationConfig
>;

export const CohortEmailNotificationExecutionDataSchema = z.null();
export type CohortEmailNotificationExecutionData = z.infer<
  typeof CohortEmailNotificationExecutionDataSchema
>;

export const CohortEmailNotificationIntegrationSpec: NotificationIntegrationSpec<CohortEmailNotificationIntegrationStruct> =
  {
    id: 'cohort.email' as const,
    configSchema: CohortEmailNotificationConfigSchema,
    notificationDataSchema: z.object({}),
    notificationInputSchema: z.object({}),
    notificationExecutionDataSchema: CohortEmailNotificationExecutionDataSchema,
  };

export const COHORT_EMAIL_SUPPORTED_USER_EVENTS_SPECS_IDS = [
  // @Devs, we need to add 'cohort.user-created' when it will be available.
  CohortBadgeGainedEventSpec,
  CohortBadgeLostEventSpec,
  CohortNewJourneyAvailableEventSpec,
  CohortNewJourneyStepAvailableEventSpec,
  CohortDigitalAssetTransferredEventSpec,
  CohortJourneyCompletedEventSpec,
  CohortPerkReceivedEventSpec,
  CohortDigitalAssetReceivedEventSpec,
  CohortJourneyStepCompletedEventSpec,
  CohortJourneyCompletedEventSpec,
  CohortNewJourneyStepAvailableEventSpec,
  CohortNewJourneyAvailableEventSpec,
  CohortUserCreatedEventSpec,
].map(spec => spec.id as EventId);
