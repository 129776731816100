/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useCohortQuery} from '@cohort/wallet/hooks/api/Query';
import {useApi} from '@cohort/wallet/hooks/useApi';
import {CampaignsApi} from '@cohort/wallet/lib/Endpoints';

export const campaignsKeys = {
  campaigns: ['campaigns'] as const,
  getStoreBySlug: (merchantId: string, campaignSlug: string, redeemCode?: string) =>
    [...campaignsKeys.campaigns, merchantId, campaignSlug, redeemCode] as const,
  getJourneyById: (merchantId: string, campaignId: string) =>
    [...campaignsKeys.campaigns, merchantId, campaignId] as const,
};

export const useStoreBySlug = (
  merchantId: string,
  campaignSlug: string,
  redeemCode?: string,
  enabled?: boolean
) => {
  const getCampaign = useApi(CampaignsApi.getStoreBySlug);

  return useCohortQuery({
    queryKey: campaignsKeys.getStoreBySlug(merchantId, campaignSlug, redeemCode),
    queryFn: async () => getCampaign(merchantId, campaignSlug, redeemCode),
    enabled,
  });
};

export const useJourneyCampaign = (merchantId: string, campaignId: string, enabled?: boolean) => {
  const getCampaign = useApi(CampaignsApi.getJourneyById);

  return useCohortQuery({
    queryKey: campaignsKeys.getJourneyById(merchantId, campaignId),
    queryFn: async () => getCampaign(merchantId, campaignId),
    enabled,
  });
};

// uses the same key as useJourneyCampaign to have a single way of refreshing a journeys
export const useStandaloneJourney = (merchantId: string, campaignId: string, enabled?: boolean) => {
  const getStandaloneJourney = useApi(CampaignsApi.getStandaloneJourneyById);

  return useCohortQuery({
    queryKey: campaignsKeys.getJourneyById(merchantId, campaignId),
    queryFn: async () => getStandaloneJourney(merchantId, campaignId),
    enabled,
  });
};
