import translationEn from '@cohort/components-xps/lib/locales/en.json';
import translationFr from '@cohort/components-xps/lib/locales/fr.json';
import i18next from 'i18next';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

// Default namespace.
export const defaultNs = 'components';

// All translations.
export const resources = {
  en: translationEn,
  fr: translationFr,
} as const;

// eslint-disable-next-line import/no-named-as-default-member
const i18nComponentsXpsInstance = i18next.createInstance();
i18nComponentsXpsInstance.init(
  {
    debug: true,
    fallbackLng: ['en', 'fr'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ['components'],
    defaultNS: defaultNs,
    resources,
  },
  err => {
    // eslint-disable-next-line no-console
    if (err) return console.log('i18nComponentsXpsInstance error:', err);
  }
);

export default i18nComponentsXpsInstance;
