import JourneyContext from '@cohort/wallet/components/contexts/JourneyContext';
import {LoadingScreen} from '@cohort/wallet/components/layout/LoadingScreen';
import {useStandaloneJourney} from '@cohort/wallet/hooks/api/Campaign';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import {Component as NotFoundPage} from '@cohort/wallet/pages/NotFoundPage';
import {useParams} from 'react-router-dom';

interface JourneyContextProviderProps {
  children: React.ReactNode;
}

const JourneyContextProvider = ({children}: JourneyContextProviderProps): JSX.Element => {
  const merchant = useMerchantContext();
  const {journeyId} = useParams();

  const {
    data: journey,
    isError,
    isFetching,
  } = useStandaloneJourney(merchant.id, journeyId ?? '', journeyId !== undefined);

  if (journey === undefined || journeyId === undefined || isFetching) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <NotFoundPage />;
  }

  return <JourneyContext.Provider value={journey}>{children}</JourneyContext.Provider>;
};

export default JourneyContextProvider;
