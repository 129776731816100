import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';

type TitleProps = {
  content: string | JSX.Element;
  testId?: string;
  className?: string;
};

const Title: React.FC<TitleProps> = ({content, testId, className}) => (
  <h1 data-testid={testId} className={cn('w-full', className)}>
    {content}
  </h1>
);

export default Title;
