import {cn} from '@cohort/shared-frontend/utils/classNames';
import {
  HoritzontalDirections,
  VerticalDirections,
} from '@cohort/wallet/components/navigation/directions';
import TrackableExternalLink from '@cohort/wallet/components/tracking/TrackableExternalLink';
import useClickTracker from '@cohort/wallet/hooks/clickTracker';
import useNotify from '@cohort/wallet/hooks/notify';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import {useNavigateWithTransition} from '@cohort/wallet/hooks/useNavigateWithTransition';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {logoutUser} from '@cohort/wallet/lib/Authentication';
import {getDefaultLoginRoute, getMerchantSpaceRoute} from '@cohort/wallet/lib/Pages';
import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';
import {Menu, Transition} from '@headlessui/react';
import {HouseLine, Question, SignIn} from '@phosphor-icons/react';
import {User} from '@phosphor-icons/react';
import {DownloadSimple} from '@phosphor-icons/react';
import {List} from '@phosphor-icons/react';
import type {SyntheticEvent} from 'react';
import React, {Fragment, useCallback} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

type DropdownNavigationProps = {
  withBackButton: boolean;
  handlelogin: () => void;
  showExperienceSpace?: boolean;
  showImport?: boolean;
  showHelp?: boolean;
  showLogout?: boolean;
  logOutSuccessRoute?: string;
  onImportNftClick?: () => void;
};

const SUPPORT_EMAIL = 'support@getcohort.com';

export const DropdownNavigation: React.FC<DropdownNavigationProps> = ({
  withBackButton,
  handlelogin,
  showExperienceSpace,
  showImport,
  showHelp,
  showLogout,
  logOutSuccessRoute,
  onImportNftClick,
}) => {
  const user = useUserStore(store => store.user);
  const navigate = useNavigate();
  const navigateWithTransition = useNavigateWithTransition();
  const {search} = useLocation();
  const {backgroundColor} = useThemeContext();
  const trackingCallback = useClickTracker('navbar.profile.toggle');
  const notify = useNotify();

  const handleLogoutClick = useCallback(async () => {
    await logoutUser();
    navigate({
      pathname: logOutSuccessRoute ?? getDefaultLoginRoute(),
      search,
    });
  }, [logOutSuccessRoute, navigate, search]);

  const {t} = useTranslation('components', {keyPrefix: 'navigation.dropdownNavigation'});

  const onHandleImportNftClick = useCallback(() => {
    onImportNftClick?.();
  }, [onImportNftClick]);

  const handleLoginClicked = useCallback(() => {
    trackingCallback();
    handlelogin();
  }, [handlelogin, trackingCallback]);

  const onGoToSpaceClicked = useCallback(() => {
    navigateWithTransition(getMerchantSpaceRoute());
  }, [navigateWithTransition]);

  if (!user) {
    return (
      <button
        key="linkbrand"
        onClick={handleLoginClicked}
        className={cn(
          withBackButton && 'hidden',
          'relative inline-flex cursor-pointer items-center rounded-md border-transparent px-4 py-2 text-sm font-medium text-[--xps-navbar-link-color] hover:bg-[--xps-navbar-link-active-background-color]'
        )}
      >
        <SignIn className="ml-0 mr-0 h-5 w-5 sm:-ml-1 sm:mr-2" aria-hidden="true" />
        <span className="hidden sm:inline">{t('signIn')}</span>
      </button>
    );
  }

  return (
    <DropdownComponent
      horizontalDirections={HoritzontalDirections.Left}
      verticalDirections={VerticalDirections.Bottom}
      leadingButton={
        <Menu.Button
          as="div"
          onClick={trackingCallback}
          className="relative inline-flex cursor-pointer items-center rounded-md border-transparent px-4 py-2 text-sm font-medium text-[--xps-navbar-link-color] hover:bg-[--xps-navbar-link-active-background-color] hover:text-[--xps-color]"
        >
          <div className="hidden sm:flex">
            <User className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            {t('profile')}
          </div>
          <div className="flex sm:hidden">
            <List className="h-5 w-5" aria-hidden="true" />
          </div>
        </Menu.Button>
      }
      trailingButton={<Fragment />}
      content={
        <div className="rounded-md" style={{backgroundColor}}>
          <div className="z-20 border-b px-4 py-3">
            <p>{t('signedAs')}</p>
            <p className="truncate font-medium text-[--xps-color]">{user.email}</p>
          </div>
          <div className="rounded-b-md py-1" style={{backgroundColor}}>
            {showExperienceSpace && (
              <DropdownChildrenButton
                handleAction={onGoToSpaceClicked}
                text={t('myNFTSpace')}
                tracking={{
                  namespace: 'navbar.profile.visitSpace',
                }}
              >
                <HouseLine size={20} className="mr-3" aria-hidden="true" />
              </DropdownChildrenButton>
            )}

            {showImport && (
              <DropdownChildrenButton
                handleAction={onHandleImportNftClick}
                text={t('importNft')}
                tracking={{
                  namespace: 'navbar.profile.importDigitalAsset',
                }}
              >
                <DownloadSimple size={20} className="mr-3" aria-hidden="true" />
              </DropdownChildrenButton>
            )}

            {showHelp && (
              <DropdownChildrenButton
                handleAction={() =>
                  notify(
                    'info',
                    <Trans
                      t={t}
                      components={{
                        contact: (
                          <a
                            className="font-medium underline"
                            href={`mailto:${SUPPORT_EMAIL}`}
                            target="_blank"
                            rel="noreferrer"
                          />
                        ),
                      }}
                    >
                      {t('helpContent', {email: SUPPORT_EMAIL})}
                    </Trans>,
                    {autoClose: false}
                  )
                }
                text={t('help')}
                tracking={{
                  namespace: 'navbar.profile.showHelpModal',
                }}
              >
                <Question size={20} className="mr-3" aria-hidden="true" />
              </DropdownChildrenButton>
            )}

            {showLogout && (
              <DropdownChildrenButton
                handleAction={() => {
                  handleLogoutClick();
                }}
                text={t('logout')}
                tracking={{
                  namespace: 'navbar.profile.logout',
                }}
              >
                <SignIn size={20} className="mr-3" aria-hidden="true" />
              </DropdownChildrenButton>
            )}
          </div>
        </div>
      }
    />
  );
};

type DropdownComponentProps = {
  className?: string;
  leadingButton?: JSX.Element[] | JSX.Element;
  trailingButton?: JSX.Element[] | JSX.Element;
  content: JSX.Element[] | JSX.Element;
  horizontalDirections: HoritzontalDirections;
  verticalDirections: VerticalDirections;
};

export const DropdownComponent: React.FC<DropdownComponentProps> = ({
  className,
  content,
  leadingButton,
  trailingButton,
  horizontalDirections,
  verticalDirections,
}: DropdownComponentProps): React.ReactElement => {
  const {backgroundColor} = useThemeContext();

  return (
    <Menu as="div" className={cn('relative inline-block cursor-pointer text-left', className)}>
      {leadingButton}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={cn(
            'absolute z-30 w-64 origin-top-left divide-y rounded-md border focus:outline-none',
            horizontalDirections === HoritzontalDirections.Left && 'right-0',
            horizontalDirections === HoritzontalDirections.Right && 'left-0',
            horizontalDirections === HoritzontalDirections.Center && 'left-1/2 -ml-11',
            verticalDirections === VerticalDirections.Top && 'bottom-12',
            verticalDirections === VerticalDirections.Bottom && 'mt-2'
          )}
          style={{backgroundColor}}
        >
          {content}
        </Menu.Items>
      </Transition>
      {trailingButton}
    </Menu>
  );
};

type DropdownChildrenButtonProps = {
  text: string;
  handleAction: () => void;
  children: React.ReactNode;
  tracking: TrackingConfig;
};

export const DropdownChildrenButton: React.FC<DropdownChildrenButtonProps> = ({
  text,
  handleAction,
  children,
  tracking,
}: DropdownChildrenButtonProps): React.ReactElement => {
  const trackingCallback = useClickTracker(tracking.namespace, tracking.metadata);

  const handleClick = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      trackingCallback();
      handleAction();
    },
    [handleAction, trackingCallback]
  );

  return (
    <Menu.Item>
      {() => (
        <button
          type="submit"
          onClick={handleClick}
          className="group flex w-full items-center px-4 py-2 text-sm text-[--xps-p-color] hover:text-[--xps-color]"
        >
          {children}
          {text}
        </button>
      )}
    </Menu.Item>
  );
};

type DropdownChildrenLinkProps = {
  text: string;
  href: string;
  children: React.ReactNode;
  tracking: TrackingConfig;
};

export const DropdownChildrenLink: React.FC<DropdownChildrenLinkProps> = ({
  text,
  href,
  tracking,
  children,
}: DropdownChildrenLinkProps): JSX.Element => (
  <Menu.Item>
    {() => (
      <TrackableExternalLink
        target="_blank"
        href={href}
        rel="noreferrer"
        className="group flex w-full items-center px-4 py-2 text-sm text-[--xps-p-color] hover:text-[--xps-color]"
        tracking={tracking}
      >
        {children}
        {text}
      </TrackableExternalLink>
    )}
  </Menu.Item>
);
