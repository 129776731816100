import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortDigitalAssetReceivedPropertiesSchema = z.object({
  collectionId: z.string().uuid(),
  digitalAssetId: z.string().uuid(),
  ownershipId: z.string().uuid(),
});
export type CohortDigitalAssetReceivedProperties = z.infer<
  typeof CohortDigitalAssetReceivedPropertiesSchema
>;

export type CohortDigitalAssetReceivedEventStruct = UserEventStruct<
  'cohort.digital-asset.received',
  keyof CohortDigitalAssetReceivedProperties,
  CohortDigitalAssetReceivedProperties,
  CohortDigitalAssetReceivedProperties
>;

export const CohortDigitalAssetReceivedEventSpec: UserEventSpec<CohortDigitalAssetReceivedEventStruct> =
  {
    id: 'cohort.digital-asset.received',
    description: 'Event Triggerred when a user receives a new Digital Asset',
    propertiesSchema: CohortDigitalAssetReceivedPropertiesSchema,
    exposedPropertiesSchema: CohortDigitalAssetReceivedPropertiesSchema,
    resources: {
      collectionId: 'cohort.digital-asset-collection',
      digitalAssetId: 'cohort.digital-asset',
    },
    rulesEngineConfig: {
      isVisible: true,
      exposedFields: ['collectionId'],
    },
  };
