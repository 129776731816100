/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {PerkIntegrationStruct} from '@cohort/shared/apps/app';
import {useCohortQuery} from '@cohort/wallet/hooks/api/Query';
import {spacesKeys} from '@cohort/wallet/hooks/api/Space';
import {useApi} from '@cohort/wallet/hooks/useApi';
import {PerkAccessesApi} from '@cohort/wallet/lib/Endpoints';

export const perkAccessesKeys = {
  perkAccesses: ['perkAccesses'] as const,
  getByAccessId: (perkAccessId: string) =>
    [...perkAccessesKeys.perkAccesses, perkAccessId] as const,
  activatePerkAccess: (perkAccessId: string) =>
    [...perkAccessesKeys.perkAccesses, ...spacesKeys.spaces, perkAccessId] as const,
  consumePerkAccess: (perkAccessId: string, connectionId: string | null) =>
    [...perkAccessesKeys.perkAccesses, ...spacesKeys.spaces, perkAccessId, connectionId] as const,
};

export const useGeneratePrivateContentUrl = (perkAccessId: string, enabled?: boolean) => {
  const generatePrivateContentUrl = useApi(PerkAccessesApi.generatePrivateContentUrl);
  return useCohortQuery({
    queryKey: perkAccessesKeys.getByAccessId(perkAccessId),
    queryFn: async () => generatePrivateContentUrl(perkAccessId),
    enabled,
  });
};

export const useActivatePerkAccess = (perkAccessId: string, enabled?: boolean) => {
  const activatePerkAccess = useApi(PerkAccessesApi.activatePerkAccess);
  return useCohortQuery({
    queryKey: perkAccessesKeys.activatePerkAccess(perkAccessId),
    queryFn: async () => activatePerkAccess(perkAccessId),
    enabled,
  });
};

export const useConsumePerkAccess = <T extends PerkIntegrationStruct = PerkIntegrationStruct>(
  perkAccessId: string,
  connectionId: string | null,
  usageInputData: T['UsageInputData'],
  enabled?: boolean
) => {
  const doUsePerkAccess = useApi(PerkAccessesApi.doUsePerkAccess);
  return useCohortQuery({
    queryKey: perkAccessesKeys.consumePerkAccess(perkAccessId, connectionId),
    queryFn: async () => doUsePerkAccess(perkAccessId, connectionId, usageInputData),
    enabled,
  });
};
